/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CohortCreateInfo
 */
export interface CohortCreateInfo {
    /**
     * Name of the underlay, immutable
     * @type {string}
     * @memberof CohortCreateInfo
     */
    underlayName?: string;
    /**
     * Human readable name of the cohort
     * @type {string}
     * @memberof CohortCreateInfo
     */
    displayName?: string;
    /**
     * Description of the cohort
     * @type {string}
     * @memberof CohortCreateInfo
     */
    description?: string;
}

export function CohortCreateInfoFromJSON(json: any): CohortCreateInfo {
    return CohortCreateInfoFromJSONTyped(json, false);
}

export function CohortCreateInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CohortCreateInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'underlayName': !exists(json, 'underlayName') ? undefined : json['underlayName'],
        'displayName': !exists(json, 'displayName') ? undefined : json['displayName'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function CohortCreateInfoToJSON(value?: CohortCreateInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'underlayName': value.underlayName,
        'displayName': value.displayName,
        'description': value.description,
    };
}


