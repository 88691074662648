/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EntityOutputPreview,
    EntityOutputPreviewFromJSON,
    EntityOutputPreviewFromJSONTyped,
    EntityOutputPreviewToJSON,
} from './';

/**
 * 
 * @export
 * @interface EntityOutputPreviewList
 */
export interface EntityOutputPreviewList {
    /**
     * 
     * @type {Array<EntityOutputPreview>}
     * @memberof EntityOutputPreviewList
     */
    entityOutputs: Array<EntityOutputPreview>;
    /**
     * SQL string for the entityId attribute.
     * @type {string}
     * @memberof EntityOutputPreviewList
     */
    entityIdSql?: string;
}

export function EntityOutputPreviewListFromJSON(json: any): EntityOutputPreviewList {
    return EntityOutputPreviewListFromJSONTyped(json, false);
}

export function EntityOutputPreviewListFromJSONTyped(json: any, ignoreDiscriminator: boolean): EntityOutputPreviewList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entityOutputs': ((json['entityOutputs'] as Array<any>).map(EntityOutputPreviewFromJSON)),
        'entityIdSql': !exists(json, 'entityIdSql') ? undefined : json['entityIdSql'],
    };
}

export function EntityOutputPreviewListToJSON(value?: EntityOutputPreviewList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entityOutputs': ((value.entityOutputs as Array<any>).map(EntityOutputPreviewToJSON)),
        'entityIdSql': value.entityIdSql,
    };
}


