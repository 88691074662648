/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Literal,
    LiteralFromJSON,
    LiteralFromJSONTyped,
    LiteralToJSON,
} from './';

/**
 * Optional related entity.
 * If specified, then we return hints computed across related instances 
 * (e.g. condition occurrences where condition id=11). Otherwise, 
 * we return hints computed across all instances (e.g. all persons).
 * @export
 * @interface HintQueryRelatedEntity
 */
export interface HintQueryRelatedEntity {
    /**
     * Related entity name
     * @type {string}
     * @memberof HintQueryRelatedEntity
     */
    name?: string;
    /**
     * 
     * @type {Literal}
     * @memberof HintQueryRelatedEntity
     */
    id?: Literal | null;
}

export function HintQueryRelatedEntityFromJSON(json: any): HintQueryRelatedEntity {
    return HintQueryRelatedEntityFromJSONTyped(json, false);
}

export function HintQueryRelatedEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): HintQueryRelatedEntity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'id': !exists(json, 'id') ? undefined : LiteralFromJSON(json['id']),
    };
}

export function HintQueryRelatedEntityToJSON(value?: HintQueryRelatedEntity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'id': LiteralToJSON(value.id),
    };
}


