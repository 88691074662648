/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Attribute,
    AttributeFromJSON,
    AttributeFromJSONTyped,
    AttributeToJSON,
    DisplayHintDisplayHint,
    DisplayHintDisplayHintFromJSON,
    DisplayHintDisplayHintFromJSONTyped,
    DisplayHintDisplayHintToJSON,
} from './';

/**
 * Display hint for entity attribute
 * @export
 * @interface DisplayHint
 */
export interface DisplayHint {
    /**
     * 
     * @type {Attribute}
     * @memberof DisplayHint
     */
    attribute?: Attribute;
    /**
     * 
     * @type {DisplayHintDisplayHint}
     * @memberof DisplayHint
     */
    displayHint?: DisplayHintDisplayHint;
}

export function DisplayHintFromJSON(json: any): DisplayHint {
    return DisplayHintFromJSONTyped(json, false);
}

export function DisplayHintFromJSONTyped(json: any, ignoreDiscriminator: boolean): DisplayHint {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attribute': !exists(json, 'attribute') ? undefined : AttributeFromJSON(json['attribute']),
        'displayHint': !exists(json, 'displayHint') ? undefined : DisplayHintDisplayHintFromJSON(json['displayHint']),
    };
}

export function DisplayHintToJSON(value?: DisplayHint | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attribute': AttributeToJSON(value.attribute),
        'displayHint': DisplayHintDisplayHintToJSON(value.displayHint),
    };
}


