/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InstanceCount,
    InstanceCountFromJSON,
    InstanceCountFromJSONTyped,
    InstanceCountToJSON,
} from './';

/**
 * List of instance counts
 * @export
 * @interface InstanceCountList
 */
export interface InstanceCountList {
    /**
     * 
     * @type {string}
     * @memberof InstanceCountList
     */
    sql?: string;
    /**
     * 
     * @type {Array<InstanceCount>}
     * @memberof InstanceCountList
     */
    instanceCounts?: Array<InstanceCount>;
    /**
     * 
     * @type {string}
     * @memberof InstanceCountList
     */
    pageMarker?: string;
    /**
     * 
     * @type {number}
     * @memberof InstanceCountList
     */
    numRowsAcrossAllPages?: number;
}

export function InstanceCountListFromJSON(json: any): InstanceCountList {
    return InstanceCountListFromJSONTyped(json, false);
}

export function InstanceCountListFromJSONTyped(json: any, ignoreDiscriminator: boolean): InstanceCountList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sql': !exists(json, 'sql') ? undefined : json['sql'],
        'instanceCounts': !exists(json, 'instanceCounts') ? undefined : ((json['instanceCounts'] as Array<any>).map(InstanceCountFromJSON)),
        'pageMarker': !exists(json, 'pageMarker') ? undefined : json['pageMarker'],
        'numRowsAcrossAllPages': !exists(json, 'numRowsAcrossAllPages') ? undefined : json['numRowsAcrossAllPages'],
    };
}

export function InstanceCountListToJSON(value?: InstanceCountList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sql': value.sql,
        'instanceCounts': value.instanceCounts === undefined ? undefined : ((value.instanceCounts as Array<any>).map(InstanceCountToJSON)),
        'pageMarker': value.pageMarker,
        'numRowsAcrossAllPages': value.numRowsAcrossAllPages,
    };
}


