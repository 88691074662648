/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Literal,
    LiteralFromJSON,
    LiteralFromJSONTyped,
    LiteralToJSON,
    QueryFilterOnPrimaryEntityOrderBys,
    QueryFilterOnPrimaryEntityOrderBysFromJSON,
    QueryFilterOnPrimaryEntityOrderBysFromJSONTyped,
    QueryFilterOnPrimaryEntityOrderBysToJSON,
} from './';

/**
 * Query for entity instances filtered for a single primary entity id
 * @export
 * @interface QueryFilterOnPrimaryEntity
 */
export interface QueryFilterOnPrimaryEntity {
    /**
     * Attributes to include in the returned instances.
     * Empty array means to include all attributes.
     * @type {Array<string>}
     * @memberof QueryFilterOnPrimaryEntity
     */
    includeAttributes: Array<string>;
    /**
     * Attributes and direction to order the results by
     * @type {Array<QueryFilterOnPrimaryEntityOrderBys>}
     * @memberof QueryFilterOnPrimaryEntity
     */
    orderBys: Array<QueryFilterOnPrimaryEntityOrderBys>;
    /**
     * 
     * @type {Literal}
     * @memberof QueryFilterOnPrimaryEntity
     */
    primaryEntityId: Literal | null;
    /**
     * 
     * @type {number}
     * @memberof QueryFilterOnPrimaryEntity
     */
    pageSize?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryFilterOnPrimaryEntity
     */
    pageMarker?: string;
}

export function QueryFilterOnPrimaryEntityFromJSON(json: any): QueryFilterOnPrimaryEntity {
    return QueryFilterOnPrimaryEntityFromJSONTyped(json, false);
}

export function QueryFilterOnPrimaryEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): QueryFilterOnPrimaryEntity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'includeAttributes': json['includeAttributes'],
        'orderBys': ((json['orderBys'] as Array<any>).map(QueryFilterOnPrimaryEntityOrderBysFromJSON)),
        'primaryEntityId': LiteralFromJSON(json['primaryEntityId']),
        'pageSize': !exists(json, 'pageSize') ? undefined : json['pageSize'],
        'pageMarker': !exists(json, 'pageMarker') ? undefined : json['pageMarker'],
    };
}

export function QueryFilterOnPrimaryEntityToJSON(value?: QueryFilterOnPrimaryEntity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'includeAttributes': value.includeAttributes,
        'orderBys': ((value.orderBys as Array<any>).map(QueryFilterOnPrimaryEntityOrderBysToJSON)),
        'primaryEntityId': LiteralToJSON(value.primaryEntityId),
        'pageSize': value.pageSize,
        'pageMarker': value.pageMarker,
    };
}


