/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StudyUpdateInfo
 */
export interface StudyUpdateInfo {
    /**
     * Human readable name of the study
     * @type {string}
     * @memberof StudyUpdateInfo
     */
    displayName?: string;
    /**
     * Description of the study
     * @type {string}
     * @memberof StudyUpdateInfo
     */
    description?: string;
}

export function StudyUpdateInfoFromJSON(json: any): StudyUpdateInfo {
    return StudyUpdateInfoFromJSONTyped(json, false);
}

export function StudyUpdateInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StudyUpdateInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'displayName': !exists(json, 'displayName') ? undefined : json['displayName'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function StudyUpdateInfoToJSON(value?: StudyUpdateInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'displayName': value.displayName,
        'description': value.description,
    };
}


