/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum DataType {
    Int64 = 'INT64',
    String = 'STRING',
    Boolean = 'BOOLEAN',
    Date = 'DATE',
    Timestamp = 'TIMESTAMP',
    Double = 'DOUBLE'
}

export function DataTypeFromJSON(json: any): DataType {
    return DataTypeFromJSONTyped(json, false);
}

export function DataTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataType {
    return json as DataType;
}

export function DataTypeToJSON(value?: DataType | null): any {
    return value as any;
}

