/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Instance,
    InstanceFromJSON,
    InstanceFromJSONTyped,
    InstanceToJSON,
} from './';

/**
 * List of instances
 * @export
 * @interface InstanceListResult
 */
export interface InstanceListResult {
    /**
     * 
     * @type {string}
     * @memberof InstanceListResult
     */
    sql?: string;
    /**
     * 
     * @type {Array<Instance>}
     * @memberof InstanceListResult
     */
    instances?: Array<Instance>;
    /**
     * 
     * @type {string}
     * @memberof InstanceListResult
     */
    pageMarker?: string;
    /**
     * 
     * @type {number}
     * @memberof InstanceListResult
     */
    numRowsAcrossAllPages?: number;
}

export function InstanceListResultFromJSON(json: any): InstanceListResult {
    return InstanceListResultFromJSONTyped(json, false);
}

export function InstanceListResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): InstanceListResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sql': !exists(json, 'sql') ? undefined : json['sql'],
        'instances': !exists(json, 'instances') ? undefined : ((json['instances'] as Array<any>).map(InstanceFromJSON)),
        'pageMarker': !exists(json, 'pageMarker') ? undefined : json['pageMarker'],
        'numRowsAcrossAllPages': !exists(json, 'numRowsAcrossAllPages') ? undefined : json['numRowsAcrossAllPages'],
    };
}

export function InstanceListResultToJSON(value?: InstanceListResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sql': value.sql,
        'instances': value.instances === undefined ? undefined : ((value.instances as Array<any>).map(InstanceToJSON)),
        'pageMarker': value.pageMarker,
        'numRowsAcrossAllPages': value.numRowsAcrossAllPages,
    };
}


