/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DisplayHintEnum,
    DisplayHintEnumFromJSON,
    DisplayHintEnumFromJSONTyped,
    DisplayHintEnumToJSON,
    DisplayHintNumericRange,
    DisplayHintNumericRangeFromJSON,
    DisplayHintNumericRangeFromJSONTyped,
    DisplayHintNumericRangeToJSON,
} from './';

/**
 * Display hint calculated from source data
 * @export
 * @interface DisplayHintDisplayHint
 */
export interface DisplayHintDisplayHint {
    /**
     * 
     * @type {DisplayHintEnum}
     * @memberof DisplayHintDisplayHint
     */
    enumHint?: DisplayHintEnum;
    /**
     * 
     * @type {DisplayHintNumericRange}
     * @memberof DisplayHintDisplayHint
     */
    numericRangeHint?: DisplayHintNumericRange;
}

export function DisplayHintDisplayHintFromJSON(json: any): DisplayHintDisplayHint {
    return DisplayHintDisplayHintFromJSONTyped(json, false);
}

export function DisplayHintDisplayHintFromJSONTyped(json: any, ignoreDiscriminator: boolean): DisplayHintDisplayHint {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'enumHint': !exists(json, 'enumHint') ? undefined : DisplayHintEnumFromJSON(json['enumHint']),
        'numericRangeHint': !exists(json, 'numericRangeHint') ? undefined : DisplayHintNumericRangeFromJSON(json['numericRangeHint']),
    };
}

export function DisplayHintDisplayHintToJSON(value?: DisplayHintDisplayHint | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'enumHint': DisplayHintEnumToJSON(value.enumHint),
        'numericRangeHint': DisplayHintNumericRangeToJSON(value.numericRangeHint),
    };
}


