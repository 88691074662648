/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Cohort,
    CohortFromJSON,
    CohortFromJSONTyped,
    CohortToJSON,
} from './';

/**
 * 
 * @export
 * @interface Review
 */
export interface Review {
    /**
     * ID of the review, immutable
     * @type {string}
     * @memberof Review
     */
    id: string;
    /**
     * Human readable name of the review
     * @type {string}
     * @memberof Review
     */
    displayName: string;
    /**
     * Description of the review
     * @type {string}
     * @memberof Review
     */
    description?: string;
    /**
     * Number of primary entity instances included in the review
     * @type {number}
     * @memberof Review
     */
    size: number;
    /**
     * 
     * @type {Cohort}
     * @memberof Review
     */
    cohort?: Cohort;
    /**
     * Timestamp of when the review was created
     * @type {Date}
     * @memberof Review
     */
    created: Date;
    /**
     * Email of user who created the review
     * @type {string}
     * @memberof Review
     */
    createdBy: string;
    /**
     * Timestamp of when the review was last modified
     * @type {Date}
     * @memberof Review
     */
    lastModified: Date;
}

export function ReviewFromJSON(json: any): Review {
    return ReviewFromJSONTyped(json, false);
}

export function ReviewFromJSONTyped(json: any, ignoreDiscriminator: boolean): Review {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'displayName': json['displayName'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'size': json['size'],
        'cohort': !exists(json, 'cohort') ? undefined : CohortFromJSON(json['cohort']),
        'created': (new Date(json['created'])),
        'createdBy': json['createdBy'],
        'lastModified': (new Date(json['lastModified'])),
    };
}

export function ReviewToJSON(value?: Review | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'displayName': value.displayName,
        'description': value.description,
        'size': value.size,
        'cohort': CohortToJSON(value.cohort),
        'created': (value.created.toISOString()),
        'createdBy': value.createdBy,
        'lastModified': (value.lastModified.toISOString()),
    };
}


