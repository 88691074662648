/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UnderlaySerializedConfiguration,
    UnderlaySerializedConfigurationFromJSON,
    UnderlaySerializedConfigurationFromJSONTyped,
    UnderlaySerializedConfigurationToJSON,
    UnderlaySummary,
    UnderlaySummaryFromJSON,
    UnderlaySummaryFromJSONTyped,
    UnderlaySummaryToJSON,
} from './';

/**
 * Underlay
 * @export
 * @interface Underlay
 */
export interface Underlay {
    /**
     * 
     * @type {UnderlaySummary}
     * @memberof Underlay
     */
    summary: UnderlaySummary;
    /**
     * 
     * @type {UnderlaySerializedConfiguration}
     * @memberof Underlay
     */
    serializedConfiguration: UnderlaySerializedConfiguration;
    /**
     * UI configuration
     * @type {string}
     * @memberof Underlay
     */
    uiConfiguration: string;
}

export function UnderlayFromJSON(json: any): Underlay {
    return UnderlayFromJSONTyped(json, false);
}

export function UnderlayFromJSONTyped(json: any, ignoreDiscriminator: boolean): Underlay {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'summary': UnderlaySummaryFromJSON(json['summary']),
        'serializedConfiguration': UnderlaySerializedConfigurationFromJSON(json['serializedConfiguration']),
        'uiConfiguration': json['uiConfiguration'],
    };
}

export function UnderlayToJSON(value?: Underlay | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'summary': UnderlaySummaryToJSON(value.summary),
        'serializedConfiguration': UnderlaySerializedConfigurationToJSON(value.serializedConfiguration),
        'uiConfiguration': value.uiConfiguration,
    };
}


