/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    EntityOutputPreviewList,
    EntityOutputPreviewListFromJSON,
    EntityOutputPreviewListToJSON,
    ErrorReport,
    ErrorReportFromJSON,
    ErrorReportToJSON,
    ExportModel,
    ExportModelFromJSON,
    ExportModelToJSON,
    ExportPreviewRequest,
    ExportPreviewRequestFromJSON,
    ExportPreviewRequestToJSON,
    ExportRequest,
    ExportRequestFromJSON,
    ExportRequestToJSON,
    ExportResult,
    ExportResultFromJSON,
    ExportResultToJSON,
    InstanceListResult,
    InstanceListResultFromJSON,
    InstanceListResultToJSON,
} from '../models';

export interface DescribeExportRequest {
    underlayName: string;
    exportPreviewRequest: ExportPreviewRequest;
}

export interface ExportInstancesAndAnnotationsRequest {
    underlayName: string;
    exportRequest: ExportRequest;
}

export interface ListExportModelsRequest {
    underlayName: string;
}

export interface PreviewExportInstancesRequest {
    underlayName: string;
    entityName: string;
    exportPreviewRequest: ExportPreviewRequest;
}

/**
 * 
 */
export class ExportApi extends runtime.BaseAPI {

    /**
     * Describe the entities and attributes that will be included in an export
     */
    async describeExportRaw(requestParameters: DescribeExportRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<EntityOutputPreviewList>> {
        if (requestParameters.underlayName === null || requestParameters.underlayName === undefined) {
            throw new runtime.RequiredError('underlayName','Required parameter requestParameters.underlayName was null or undefined when calling describeExport.');
        }

        if (requestParameters.exportPreviewRequest === null || requestParameters.exportPreviewRequest === undefined) {
            throw new runtime.RequiredError('exportPreviewRequest','Required parameter requestParameters.exportPreviewRequest was null or undefined when calling describeExport.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("authorization", ["openid", "email", "profile"]);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/underlays/{underlayName}/describeExport`.replace(`{${"underlayName"}}`, encodeURIComponent(String(requestParameters.underlayName))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExportPreviewRequestToJSON(requestParameters.exportPreviewRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EntityOutputPreviewListFromJSON(jsonValue));
    }

    /**
     * Describe the entities and attributes that will be included in an export
     */
    async describeExport(requestParameters: DescribeExportRequest, initOverrides?: RequestInit): Promise<EntityOutputPreviewList> {
        const response = await this.describeExportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Export entity instances and review annotations
     */
    async exportInstancesAndAnnotationsRaw(requestParameters: ExportInstancesAndAnnotationsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ExportResult>> {
        if (requestParameters.underlayName === null || requestParameters.underlayName === undefined) {
            throw new runtime.RequiredError('underlayName','Required parameter requestParameters.underlayName was null or undefined when calling exportInstancesAndAnnotations.');
        }

        if (requestParameters.exportRequest === null || requestParameters.exportRequest === undefined) {
            throw new runtime.RequiredError('exportRequest','Required parameter requestParameters.exportRequest was null or undefined when calling exportInstancesAndAnnotations.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("authorization", ["openid", "email", "profile"]);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/underlays/{underlayName}/export`.replace(`{${"underlayName"}}`, encodeURIComponent(String(requestParameters.underlayName))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExportRequestToJSON(requestParameters.exportRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExportResultFromJSON(jsonValue));
    }

    /**
     * Export entity instances and review annotations
     */
    async exportInstancesAndAnnotations(requestParameters: ExportInstancesAndAnnotationsRequest, initOverrides?: RequestInit): Promise<ExportResult> {
        const response = await this.exportInstancesAndAnnotationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List the available export models
     */
    async listExportModelsRaw(requestParameters: ListExportModelsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ExportModel>>> {
        if (requestParameters.underlayName === null || requestParameters.underlayName === undefined) {
            throw new runtime.RequiredError('underlayName','Required parameter requestParameters.underlayName was null or undefined when calling listExportModels.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("authorization", ["openid", "email", "profile"]);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/underlays/{underlayName}/exportModels`.replace(`{${"underlayName"}}`, encodeURIComponent(String(requestParameters.underlayName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ExportModelFromJSON));
    }

    /**
     * List the available export models
     */
    async listExportModels(requestParameters: ListExportModelsRequest, initOverrides?: RequestInit): Promise<Array<ExportModel>> {
        const response = await this.listExportModelsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List entity instances that will be included in an export
     */
    async previewExportInstancesRaw(requestParameters: PreviewExportInstancesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InstanceListResult>> {
        if (requestParameters.underlayName === null || requestParameters.underlayName === undefined) {
            throw new runtime.RequiredError('underlayName','Required parameter requestParameters.underlayName was null or undefined when calling previewExportInstances.');
        }

        if (requestParameters.entityName === null || requestParameters.entityName === undefined) {
            throw new runtime.RequiredError('entityName','Required parameter requestParameters.entityName was null or undefined when calling previewExportInstances.');
        }

        if (requestParameters.exportPreviewRequest === null || requestParameters.exportPreviewRequest === undefined) {
            throw new runtime.RequiredError('exportPreviewRequest','Required parameter requestParameters.exportPreviewRequest was null or undefined when calling previewExportInstances.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("authorization", ["openid", "email", "profile"]);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/underlays/{underlayName}/entities/{entityName}/previewExport`.replace(`{${"underlayName"}}`, encodeURIComponent(String(requestParameters.underlayName))).replace(`{${"entityName"}}`, encodeURIComponent(String(requestParameters.entityName))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExportPreviewRequestToJSON(requestParameters.exportPreviewRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InstanceListResultFromJSON(jsonValue));
    }

    /**
     * List entity instances that will be included in an export
     */
    async previewExportInstances(requestParameters: PreviewExportInstancesRequest, initOverrides?: RequestInit): Promise<InstanceListResult> {
        const response = await this.previewExportInstancesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
