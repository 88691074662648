/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CountQuery,
    CountQueryFromJSON,
    CountQueryToJSON,
    CriteriaCountQuery,
    CriteriaCountQueryFromJSON,
    CriteriaCountQueryToJSON,
    DisplayHintList,
    DisplayHintListFromJSON,
    DisplayHintListToJSON,
    Entity,
    EntityFromJSON,
    EntityToJSON,
    EntityList,
    EntityListFromJSON,
    EntityListToJSON,
    ErrorReport,
    ErrorReportFromJSON,
    ErrorReportToJSON,
    HintQuery,
    HintQueryFromJSON,
    HintQueryToJSON,
    InstanceCountList,
    InstanceCountListFromJSON,
    InstanceCountListToJSON,
    InstanceListResult,
    InstanceListResultFromJSON,
    InstanceListResultToJSON,
    Query,
    QueryFromJSON,
    QueryToJSON,
    QueryFilterOnPrimaryEntity,
    QueryFilterOnPrimaryEntityFromJSON,
    QueryFilterOnPrimaryEntityToJSON,
    Underlay,
    UnderlayFromJSON,
    UnderlayToJSON,
    UnderlaySummaryList,
    UnderlaySummaryListFromJSON,
    UnderlaySummaryListToJSON,
} from '../models';

export interface CountInstancesRequest {
    underlayName: string;
    entityName: string;
    countQuery: CountQuery;
}

export interface GetEntityRequest {
    underlayName: string;
    entityName: string;
}

export interface GetUnderlayRequest {
    underlayName: string;
}

export interface ListEntitiesRequest {
    underlayName: string;
}

export interface ListInstancesRequest {
    underlayName: string;
    entityName: string;
    query: Query;
}

export interface ListInstancesForPrimaryEntityRequest {
    underlayName: string;
    entityName: string;
    queryFilterOnPrimaryEntity: QueryFilterOnPrimaryEntity;
}

export interface QueryCriteriaCountsRequest {
    underlayName: string;
    criteriaCountQuery: CriteriaCountQuery;
}

export interface QueryHintsRequest {
    underlayName: string;
    entityName: string;
    hintQuery: HintQuery;
}

/**
 * 
 */
export class UnderlaysApi extends runtime.BaseAPI {

    /**
     * Count entity instances
     */
    async countInstancesRaw(requestParameters: CountInstancesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InstanceCountList>> {
        if (requestParameters.underlayName === null || requestParameters.underlayName === undefined) {
            throw new runtime.RequiredError('underlayName','Required parameter requestParameters.underlayName was null or undefined when calling countInstances.');
        }

        if (requestParameters.entityName === null || requestParameters.entityName === undefined) {
            throw new runtime.RequiredError('entityName','Required parameter requestParameters.entityName was null or undefined when calling countInstances.');
        }

        if (requestParameters.countQuery === null || requestParameters.countQuery === undefined) {
            throw new runtime.RequiredError('countQuery','Required parameter requestParameters.countQuery was null or undefined when calling countInstances.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("authorization", ["openid", "email", "profile"]);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/underlays/{underlayName}/entities/{entityName}/counts`.replace(`{${"underlayName"}}`, encodeURIComponent(String(requestParameters.underlayName))).replace(`{${"entityName"}}`, encodeURIComponent(String(requestParameters.entityName))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CountQueryToJSON(requestParameters.countQuery),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InstanceCountListFromJSON(jsonValue));
    }

    /**
     * Count entity instances
     */
    async countInstances(requestParameters: CountInstancesRequest, initOverrides?: RequestInit): Promise<InstanceCountList> {
        const response = await this.countInstancesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get an entity
     */
    async getEntityRaw(requestParameters: GetEntityRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Entity>> {
        if (requestParameters.underlayName === null || requestParameters.underlayName === undefined) {
            throw new runtime.RequiredError('underlayName','Required parameter requestParameters.underlayName was null or undefined when calling getEntity.');
        }

        if (requestParameters.entityName === null || requestParameters.entityName === undefined) {
            throw new runtime.RequiredError('entityName','Required parameter requestParameters.entityName was null or undefined when calling getEntity.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("authorization", ["openid", "email", "profile"]);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/underlays/{underlayName}/entities/{entityName}`.replace(`{${"underlayName"}}`, encodeURIComponent(String(requestParameters.underlayName))).replace(`{${"entityName"}}`, encodeURIComponent(String(requestParameters.entityName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EntityFromJSON(jsonValue));
    }

    /**
     * Get an entity
     */
    async getEntity(requestParameters: GetEntityRequest, initOverrides?: RequestInit): Promise<Entity> {
        const response = await this.getEntityRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get an underlay
     */
    async getUnderlayRaw(requestParameters: GetUnderlayRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Underlay>> {
        if (requestParameters.underlayName === null || requestParameters.underlayName === undefined) {
            throw new runtime.RequiredError('underlayName','Required parameter requestParameters.underlayName was null or undefined when calling getUnderlay.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("authorization", ["openid", "email", "profile"]);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/underlays/{underlayName}`.replace(`{${"underlayName"}}`, encodeURIComponent(String(requestParameters.underlayName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UnderlayFromJSON(jsonValue));
    }

    /**
     * Get an underlay
     */
    async getUnderlay(requestParameters: GetUnderlayRequest, initOverrides?: RequestInit): Promise<Underlay> {
        const response = await this.getUnderlayRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List the entities in an underlay
     */
    async listEntitiesRaw(requestParameters: ListEntitiesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<EntityList>> {
        if (requestParameters.underlayName === null || requestParameters.underlayName === undefined) {
            throw new runtime.RequiredError('underlayName','Required parameter requestParameters.underlayName was null or undefined when calling listEntities.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("authorization", ["openid", "email", "profile"]);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/underlays/{underlayName}/entities`.replace(`{${"underlayName"}}`, encodeURIComponent(String(requestParameters.underlayName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EntityListFromJSON(jsonValue));
    }

    /**
     * List the entities in an underlay
     */
    async listEntities(requestParameters: ListEntitiesRequest, initOverrides?: RequestInit): Promise<EntityList> {
        const response = await this.listEntitiesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List entity instances
     */
    async listInstancesRaw(requestParameters: ListInstancesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InstanceListResult>> {
        if (requestParameters.underlayName === null || requestParameters.underlayName === undefined) {
            throw new runtime.RequiredError('underlayName','Required parameter requestParameters.underlayName was null or undefined when calling listInstances.');
        }

        if (requestParameters.entityName === null || requestParameters.entityName === undefined) {
            throw new runtime.RequiredError('entityName','Required parameter requestParameters.entityName was null or undefined when calling listInstances.');
        }

        if (requestParameters.query === null || requestParameters.query === undefined) {
            throw new runtime.RequiredError('query','Required parameter requestParameters.query was null or undefined when calling listInstances.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("authorization", ["openid", "email", "profile"]);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/underlays/{underlayName}/entities/{entityName}/instances`.replace(`{${"underlayName"}}`, encodeURIComponent(String(requestParameters.underlayName))).replace(`{${"entityName"}}`, encodeURIComponent(String(requestParameters.entityName))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QueryToJSON(requestParameters.query),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InstanceListResultFromJSON(jsonValue));
    }

    /**
     * List entity instances
     */
    async listInstances(requestParameters: ListInstancesRequest, initOverrides?: RequestInit): Promise<InstanceListResult> {
        const response = await this.listInstancesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List entity instances filtered for a single primary entity id
     */
    async listInstancesForPrimaryEntityRaw(requestParameters: ListInstancesForPrimaryEntityRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InstanceListResult>> {
        if (requestParameters.underlayName === null || requestParameters.underlayName === undefined) {
            throw new runtime.RequiredError('underlayName','Required parameter requestParameters.underlayName was null or undefined when calling listInstancesForPrimaryEntity.');
        }

        if (requestParameters.entityName === null || requestParameters.entityName === undefined) {
            throw new runtime.RequiredError('entityName','Required parameter requestParameters.entityName was null or undefined when calling listInstancesForPrimaryEntity.');
        }

        if (requestParameters.queryFilterOnPrimaryEntity === null || requestParameters.queryFilterOnPrimaryEntity === undefined) {
            throw new runtime.RequiredError('queryFilterOnPrimaryEntity','Required parameter requestParameters.queryFilterOnPrimaryEntity was null or undefined when calling listInstancesForPrimaryEntity.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("authorization", ["openid", "email", "profile"]);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/underlays/{underlayName}/entities/{entityName}/instancesForPrimaryEntity`.replace(`{${"underlayName"}}`, encodeURIComponent(String(requestParameters.underlayName))).replace(`{${"entityName"}}`, encodeURIComponent(String(requestParameters.entityName))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QueryFilterOnPrimaryEntityToJSON(requestParameters.queryFilterOnPrimaryEntity),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InstanceListResultFromJSON(jsonValue));
    }

    /**
     * List entity instances filtered for a single primary entity id
     */
    async listInstancesForPrimaryEntity(requestParameters: ListInstancesForPrimaryEntityRequest, initOverrides?: RequestInit): Promise<InstanceListResult> {
        const response = await this.listInstancesForPrimaryEntityRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List the underlay summaries
     */
    async listUnderlaySummariesRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<UnderlaySummaryList>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("authorization", ["openid", "email", "profile"]);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/underlays`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UnderlaySummaryListFromJSON(jsonValue));
    }

    /**
     * List the underlay summaries
     */
    async listUnderlaySummaries(initOverrides?: RequestInit): Promise<UnderlaySummaryList> {
        const response = await this.listUnderlaySummariesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Run a count query for a search criteria
     */
    async queryCriteriaCountsRaw(requestParameters: QueryCriteriaCountsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InstanceCountList>> {
        if (requestParameters.underlayName === null || requestParameters.underlayName === undefined) {
            throw new runtime.RequiredError('underlayName','Required parameter requestParameters.underlayName was null or undefined when calling queryCriteriaCounts.');
        }

        if (requestParameters.criteriaCountQuery === null || requestParameters.criteriaCountQuery === undefined) {
            throw new runtime.RequiredError('criteriaCountQuery','Required parameter requestParameters.criteriaCountQuery was null or undefined when calling queryCriteriaCounts.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("authorization", ["openid", "email", "profile"]);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/underlays/{underlayName}/criteriaCounts`.replace(`{${"underlayName"}}`, encodeURIComponent(String(requestParameters.underlayName))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CriteriaCountQueryToJSON(requestParameters.criteriaCountQuery),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InstanceCountListFromJSON(jsonValue));
    }

    /**
     * Run a count query for a search criteria
     */
    async queryCriteriaCounts(requestParameters: QueryCriteriaCountsRequest, initOverrides?: RequestInit): Promise<InstanceCountList> {
        const response = await this.queryCriteriaCountsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Query entity display hints
     */
    async queryHintsRaw(requestParameters: QueryHintsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DisplayHintList>> {
        if (requestParameters.underlayName === null || requestParameters.underlayName === undefined) {
            throw new runtime.RequiredError('underlayName','Required parameter requestParameters.underlayName was null or undefined when calling queryHints.');
        }

        if (requestParameters.entityName === null || requestParameters.entityName === undefined) {
            throw new runtime.RequiredError('entityName','Required parameter requestParameters.entityName was null or undefined when calling queryHints.');
        }

        if (requestParameters.hintQuery === null || requestParameters.hintQuery === undefined) {
            throw new runtime.RequiredError('hintQuery','Required parameter requestParameters.hintQuery was null or undefined when calling queryHints.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("authorization", ["openid", "email", "profile"]);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/underlays/{underlayName}/entities/{entityName}/hints`.replace(`{${"underlayName"}}`, encodeURIComponent(String(requestParameters.underlayName))).replace(`{${"entityName"}}`, encodeURIComponent(String(requestParameters.entityName))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HintQueryToJSON(requestParameters.hintQuery),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DisplayHintListFromJSON(jsonValue));
    }

    /**
     * Query entity display hints
     */
    async queryHints(requestParameters: QueryHintsRequest, initOverrides?: RequestInit): Promise<DisplayHintList> {
        const response = await this.queryHintsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
