/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InstanceRelationshipFields
 */
export interface InstanceRelationshipFields {
    /**
     * 
     * @type {string}
     * @memberof InstanceRelationshipFields
     */
    relatedEntity?: string;
    /**
     * 
     * @type {string}
     * @memberof InstanceRelationshipFields
     */
    hierarchy?: string;
    /**
     * 
     * @type {number}
     * @memberof InstanceRelationshipFields
     */
    count?: number;
    /**
     * 
     * @type {string}
     * @memberof InstanceRelationshipFields
     */
    displayHints?: string;
}

export function InstanceRelationshipFieldsFromJSON(json: any): InstanceRelationshipFields {
    return InstanceRelationshipFieldsFromJSONTyped(json, false);
}

export function InstanceRelationshipFieldsFromJSONTyped(json: any, ignoreDiscriminator: boolean): InstanceRelationshipFields {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'relatedEntity': !exists(json, 'relatedEntity') ? undefined : json['relatedEntity'],
        'hierarchy': !exists(json, 'hierarchy') ? undefined : json['hierarchy'],
        'count': !exists(json, 'count') ? undefined : json['count'],
        'displayHints': !exists(json, 'displayHints') ? undefined : json['displayHints'],
    };
}

export function InstanceRelationshipFieldsToJSON(value?: InstanceRelationshipFields | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'relatedEntity': value.relatedEntity,
        'hierarchy': value.hierarchy,
        'count': value.count,
        'displayHints': value.displayHints,
    };
}


