/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EntityOutputPreviewCriteria
 */
export interface EntityOutputPreviewCriteria {
    /**
     * 
     * @type {string}
     * @memberof EntityOutputPreviewCriteria
     */
    featureSetId: string;
    /**
     * 
     * @type {string}
     * @memberof EntityOutputPreviewCriteria
     */
    criteriaId: string;
}

export function EntityOutputPreviewCriteriaFromJSON(json: any): EntityOutputPreviewCriteria {
    return EntityOutputPreviewCriteriaFromJSONTyped(json, false);
}

export function EntityOutputPreviewCriteriaFromJSONTyped(json: any, ignoreDiscriminator: boolean): EntityOutputPreviewCriteria {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'featureSetId': json['featureSetId'],
        'criteriaId': json['criteriaId'],
    };
}

export function EntityOutputPreviewCriteriaToJSON(value?: EntityOutputPreviewCriteria | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'featureSetId': value.featureSetId,
        'criteriaId': value.criteriaId,
    };
}


