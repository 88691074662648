/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FilterFilterUnion,
    FilterFilterUnionFromJSON,
    FilterFilterUnionFromJSONTyped,
    FilterFilterUnionToJSON,
} from './';

/**
 * Union of references to each filter type. Exactly one should be specified based on the filterType.
 * @export
 * @interface Filter
 */
export interface Filter {
    /**
     * 
     * @type {string}
     * @memberof Filter
     */
    filterType?: FilterFilterTypeEnum;
    /**
     * 
     * @type {FilterFilterUnion}
     * @memberof Filter
     */
    filterUnion?: FilterFilterUnion;
}

/**
* @export
* @enum {string}
*/
export enum FilterFilterTypeEnum {
    Attribute = 'ATTRIBUTE',
    Text = 'TEXT',
    Hierarchy = 'HIERARCHY',
    Relationship = 'RELATIONSHIP',
    BooleanLogic = 'BOOLEAN_LOGIC',
    ItemInGroup = 'ITEM_IN_GROUP',
    GroupHasItems = 'GROUP_HAS_ITEMS',
    OccurrenceForPrimary = 'OCCURRENCE_FOR_PRIMARY',
    PrimaryWithCriteria = 'PRIMARY_WITH_CRITERIA'
}

export function FilterFromJSON(json: any): Filter {
    return FilterFromJSONTyped(json, false);
}

export function FilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): Filter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'filterType': !exists(json, 'filterType') ? undefined : json['filterType'],
        'filterUnion': !exists(json, 'filterUnion') ? undefined : FilterFilterUnionFromJSON(json['filterUnion']),
    };
}

export function FilterToJSON(value?: Filter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'filterType': value.filterType,
        'filterUnion': FilterFilterUnionToJSON(value.filterUnion),
    };
}


