/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExportLinkResult,
    ExportLinkResultFromJSON,
    ExportLinkResultFromJSONTyped,
    ExportLinkResultToJSON,
} from './';

/**
 * 
 * @export
 * @interface ExportResult
 */
export interface ExportResult {
    /**
     * 
     * @type {string}
     * @memberof ExportResult
     */
    status: ExportResultStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ExportResult
     */
    redirectAwayUrl?: string;
    /**
     * Key-value map of output data generated by the export model
     * @type {{ [key: string]: string; }}
     * @memberof ExportResult
     */
    outputs: { [key: string]: string; };
    /**
     * Output link results generated by the export model
     * @type {Array<ExportLinkResult>}
     * @memberof ExportResult
     */
    links: Array<ExportLinkResult>;
    /**
     * Error message if this export failed outside of individual link generation
     * @type {string}
     * @memberof ExportResult
     */
    error?: string;
}

/**
* @export
* @enum {string}
*/
export enum ExportResultStatusEnum {
    Succeeded = 'SUCCEEDED',
    Failed = 'FAILED'
}

export function ExportResultFromJSON(json: any): ExportResult {
    return ExportResultFromJSONTyped(json, false);
}

export function ExportResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExportResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': json['status'],
        'redirectAwayUrl': !exists(json, 'redirectAwayUrl') ? undefined : json['redirectAwayUrl'],
        'outputs': json['outputs'],
        'links': ((json['links'] as Array<any>).map(ExportLinkResultFromJSON)),
        'error': !exists(json, 'error') ? undefined : json['error'],
    };
}

export function ExportResultToJSON(value?: ExportResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': value.status,
        'redirectAwayUrl': value.redirectAwayUrl,
        'outputs': value.outputs,
        'links': ((value.links as Array<any>).map(ExportLinkResultToJSON)),
        'error': value.error,
    };
}


