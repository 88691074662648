/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExportRequest
 */
export interface ExportRequest {
    /**
     * Name of the export model to use
     * @type {string}
     * @memberof ExportRequest
     */
    exportModel?: string;
    /**
     * Key-value map of the export model inputs (e.g. filenamePrefix=test)
     * @type {{ [key: string]: string; }}
     * @memberof ExportRequest
     */
    inputs?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof ExportRequest
     */
    redirectBackUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExportRequest
     */
    study?: string;
    /**
     * List of cohort ids
     * @type {Array<string>}
     * @memberof ExportRequest
     */
    cohorts?: Array<string>;
    /**
     * List of feature set ids
     * @type {Array<string>}
     * @memberof ExportRequest
     */
    featureSets?: Array<string>;
    /**
     * Include the annotation data when true
     * @type {boolean}
     * @memberof ExportRequest
     */
    includeAnnotations?: boolean;
}

export function ExportRequestFromJSON(json: any): ExportRequest {
    return ExportRequestFromJSONTyped(json, false);
}

export function ExportRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExportRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'exportModel': !exists(json, 'exportModel') ? undefined : json['exportModel'],
        'inputs': !exists(json, 'inputs') ? undefined : json['inputs'],
        'redirectBackUrl': !exists(json, 'redirectBackUrl') ? undefined : json['redirectBackUrl'],
        'study': !exists(json, 'study') ? undefined : json['study'],
        'cohorts': !exists(json, 'cohorts') ? undefined : json['cohorts'],
        'featureSets': !exists(json, 'featureSets') ? undefined : json['featureSets'],
        'includeAnnotations': !exists(json, 'includeAnnotations') ? undefined : json['includeAnnotations'],
    };
}

export function ExportRequestToJSON(value?: ExportRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'exportModel': value.exportModel,
        'inputs': value.inputs,
        'redirectBackUrl': value.redirectBackUrl,
        'study': value.study,
        'cohorts': value.cohorts,
        'featureSets': value.featureSets,
        'includeAnnotations': value.includeAnnotations,
    };
}


