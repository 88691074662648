/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ValueDisplay,
    ValueDisplayFromJSON,
    ValueDisplayFromJSONTyped,
    ValueDisplayToJSON,
} from './';

/**
 * Enum value and count
 * @export
 * @interface DisplayHintEnumEnumHintValues
 */
export interface DisplayHintEnumEnumHintValues {
    /**
     * 
     * @type {ValueDisplay}
     * @memberof DisplayHintEnumEnumHintValues
     */
    enumVal?: ValueDisplay;
    /**
     * 
     * @type {number}
     * @memberof DisplayHintEnumEnumHintValues
     */
    count?: number;
}

export function DisplayHintEnumEnumHintValuesFromJSON(json: any): DisplayHintEnumEnumHintValues {
    return DisplayHintEnumEnumHintValuesFromJSONTyped(json, false);
}

export function DisplayHintEnumEnumHintValuesFromJSONTyped(json: any, ignoreDiscriminator: boolean): DisplayHintEnumEnumHintValues {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'enumVal': !exists(json, 'enumVal') ? undefined : ValueDisplayFromJSON(json['enumVal']),
        'count': !exists(json, 'count') ? undefined : json['count'],
    };
}

export function DisplayHintEnumEnumHintValuesToJSON(value?: DisplayHintEnumEnumHintValues | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'enumVal': ValueDisplayToJSON(value.enumVal),
        'count': value.count,
    };
}


