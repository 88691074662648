/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrderByDirection,
    OrderByDirectionFromJSON,
    OrderByDirectionFromJSONTyped,
    OrderByDirectionToJSON,
    QueryRelationshipField,
    QueryRelationshipFieldFromJSON,
    QueryRelationshipFieldFromJSONTyped,
    QueryRelationshipFieldToJSON,
} from './';

/**
 * Attribute or relationship (count) field and the direction
 * @export
 * @interface QueryOrderBys
 */
export interface QueryOrderBys {
    /**
     * 
     * @type {string}
     * @memberof QueryOrderBys
     */
    attribute?: string;
    /**
     * 
     * @type {QueryRelationshipField}
     * @memberof QueryOrderBys
     */
    relationshipField?: QueryRelationshipField;
    /**
     * 
     * @type {OrderByDirection}
     * @memberof QueryOrderBys
     */
    direction?: OrderByDirection;
}

export function QueryOrderBysFromJSON(json: any): QueryOrderBys {
    return QueryOrderBysFromJSONTyped(json, false);
}

export function QueryOrderBysFromJSONTyped(json: any, ignoreDiscriminator: boolean): QueryOrderBys {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attribute': !exists(json, 'attribute') ? undefined : json['attribute'],
        'relationshipField': !exists(json, 'relationshipField') ? undefined : QueryRelationshipFieldFromJSON(json['relationshipField']),
        'direction': !exists(json, 'direction') ? undefined : OrderByDirectionFromJSON(json['direction']),
    };
}

export function QueryOrderBysToJSON(value?: QueryOrderBys | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attribute': value.attribute,
        'relationshipField': QueryRelationshipFieldToJSON(value.relationshipField),
        'direction': OrderByDirectionToJSON(value.direction),
    };
}


