/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PropertyKeyValue,
    PropertyKeyValueFromJSON,
    PropertyKeyValueFromJSONTyped,
    PropertyKeyValueToJSON,
} from './';

/**
 * 
 * @export
 * @interface Study
 */
export interface Study {
    /**
     * ID of the study, immutable
     * @type {string}
     * @memberof Study
     */
    id: string;
    /**
     * Human readable name of the study
     * @type {string}
     * @memberof Study
     */
    displayName?: string;
    /**
     * Description of the study
     * @type {string}
     * @memberof Study
     */
    description?: string;
    /**
     * List of key-value pairs of strings
     * @type {Array<PropertyKeyValue>}
     * @memberof Study
     */
    properties?: Array<PropertyKeyValue>;
    /**
     * 
     * @type {Date}
     * @memberof Study
     */
    created: Date;
    /**
     * Email of user who created the study
     * @type {string}
     * @memberof Study
     */
    createdBy: string;
    /**
     * 
     * @type {Date}
     * @memberof Study
     */
    lastModified: Date;
    /**
     * 
     * @type {boolean}
     * @memberof Study
     */
    isDeleted?: boolean;
}

export function StudyFromJSON(json: any): Study {
    return StudyFromJSONTyped(json, false);
}

export function StudyFromJSONTyped(json: any, ignoreDiscriminator: boolean): Study {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'displayName': !exists(json, 'displayName') ? undefined : json['displayName'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'properties': !exists(json, 'properties') ? undefined : ((json['properties'] as Array<any>).map(PropertyKeyValueFromJSON)),
        'created': (new Date(json['created'])),
        'createdBy': json['createdBy'],
        'lastModified': (new Date(json['lastModified'])),
        'isDeleted': !exists(json, 'isDeleted') ? undefined : json['isDeleted'],
    };
}

export function StudyToJSON(value?: Study | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'displayName': value.displayName,
        'description': value.description,
        'properties': value.properties === undefined ? undefined : ((value.properties as Array<any>).map(PropertyKeyValueToJSON)),
        'created': (value.created.toISOString()),
        'createdBy': value.createdBy,
        'lastModified': (value.lastModified.toISOString()),
        'isDeleted': value.isDeleted,
    };
}


