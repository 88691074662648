/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DataType,
    DataTypeFromJSON,
    DataTypeFromJSONTyped,
    DataTypeToJSON,
    LiteralValueUnion,
    LiteralValueUnionFromJSON,
    LiteralValueUnionFromJSONTyped,
    LiteralValueUnionToJSON,
} from './';

/**
 * Union of references to each value type. Exactly one will be populated based on the dataType.
 * @export
 * @interface Literal
 */
export interface Literal {
    /**
     * 
     * @type {DataType}
     * @memberof Literal
     */
    dataType?: DataType;
    /**
     * 
     * @type {LiteralValueUnion}
     * @memberof Literal
     */
    valueUnion?: LiteralValueUnion;
}

export function LiteralFromJSON(json: any): Literal {
    return LiteralFromJSONTyped(json, false);
}

export function LiteralFromJSONTyped(json: any, ignoreDiscriminator: boolean): Literal {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dataType': !exists(json, 'dataType') ? undefined : DataTypeFromJSON(json['dataType']),
        'valueUnion': !exists(json, 'valueUnion') ? undefined : LiteralValueUnionFromJSON(json['valueUnion']),
    };
}

export function LiteralToJSON(value?: Literal | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dataType': DataTypeToJSON(value.dataType),
        'valueUnion': LiteralValueUnionToJSON(value.valueUnion),
    };
}


