/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EntityOutput
 */
export interface EntityOutput {
    /**
     * Entity name
     * @type {string}
     * @memberof EntityOutput
     */
    entity: string;
    /**
     * Names of attributes to exclude from output
     * @type {Array<string>}
     * @memberof EntityOutput
     */
    excludeAttributes?: Array<string>;
}

export function EntityOutputFromJSON(json: any): EntityOutput {
    return EntityOutputFromJSONTyped(json, false);
}

export function EntityOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): EntityOutput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entity': json['entity'],
        'excludeAttributes': !exists(json, 'excludeAttributes') ? undefined : json['excludeAttributes'],
    };
}

export function EntityOutputToJSON(value?: EntityOutput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entity': value.entity,
        'excludeAttributes': value.excludeAttributes,
    };
}


