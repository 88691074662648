/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ValueDisplay,
    ValueDisplayFromJSON,
    ValueDisplayFromJSONTyped,
    ValueDisplayToJSON,
} from './';

/**
 * 
 * @export
 * @interface InstanceCount
 */
export interface InstanceCount {
    /**
     * 
     * @type {number}
     * @memberof InstanceCount
     */
    count?: number;
    /**
     * A map of entity attribute names to their value for this group
     * @type {{ [key: string]: ValueDisplay; }}
     * @memberof InstanceCount
     */
    attributes?: { [key: string]: ValueDisplay; };
}

export function InstanceCountFromJSON(json: any): InstanceCount {
    return InstanceCountFromJSONTyped(json, false);
}

export function InstanceCountFromJSONTyped(json: any, ignoreDiscriminator: boolean): InstanceCount {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': !exists(json, 'count') ? undefined : json['count'],
        'attributes': !exists(json, 'attributes') ? undefined : (mapValues(json['attributes'], ValueDisplayFromJSON)),
    };
}

export function InstanceCountToJSON(value?: InstanceCount | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'attributes': value.attributes === undefined ? undefined : (mapValues(value.attributes, ValueDisplayToJSON)),
    };
}


