/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CriteriaGroup,
    CriteriaGroupFromJSON,
    CriteriaGroupFromJSONTyped,
    CriteriaGroupToJSON,
    ReducingOperator,
    ReducingOperatorFromJSON,
    ReducingOperatorFromJSONTyped,
    ReducingOperatorToJSON,
} from './';

/**
 * Section of criteria groups and boolean logic operators to combine them
 * @export
 * @interface CriteriaGroupSection
 */
export interface CriteriaGroupSection {
    /**
     * ID of the section, immutable
     * @type {string}
     * @memberof CriteriaGroupSection
     */
    id: string;
    /**
     * Name of the section
     * @type {string}
     * @memberof CriteriaGroupSection
     */
    displayName: string;
    /**
     * For a temporal section, this is the set of groups that define the first block.
     * For a non-temporal section, the set of groups are the union of this list and the secondBlockCriteriaGroups list.
     * @type {Array<CriteriaGroup>}
     * @memberof CriteriaGroupSection
     */
    criteriaGroups: Array<CriteriaGroup>;
    /**
     * 
     * @type {ReducingOperator}
     * @memberof CriteriaGroupSection
     */
    firstBlockReducingOperator?: ReducingOperator;
    /**
     * For a temporal section, this is the set of groups that define the second block.
     * For a non-temporal section, the set of groups are the union of this list and the criteriaGroups list.
     * @type {Array<CriteriaGroup>}
     * @memberof CriteriaGroupSection
     */
    secondBlockCriteriaGroups?: Array<CriteriaGroup>;
    /**
     * 
     * @type {ReducingOperator}
     * @memberof CriteriaGroupSection
     */
    secondBlockReducingOperator?: ReducingOperator;
    /**
     * Operator to use when combining the criteria groups in the section
     * @type {string}
     * @memberof CriteriaGroupSection
     */
    operator: CriteriaGroupSectionOperatorEnum;
    /**
     * Value associated with the operator (e.g. num days)
     * @type {number}
     * @memberof CriteriaGroupSection
     */
    operatorValue?: number;
    /**
     * True to exclude the section, false to include it
     * @type {boolean}
     * @memberof CriteriaGroupSection
     */
    excluded: boolean;
    /**
     * True to disable the section
     * @type {boolean}
     * @memberof CriteriaGroupSection
     */
    disabled?: boolean;
}

/**
* @export
* @enum {string}
*/
export enum CriteriaGroupSectionOperatorEnum {
    And = 'AND',
    Or = 'OR',
    DuringSameEncounter = 'DURING_SAME_ENCOUNTER',
    NumDaysBefore = 'NUM_DAYS_BEFORE',
    NumDaysAfter = 'NUM_DAYS_AFTER',
    WithinNumDays = 'WITHIN_NUM_DAYS'
}

export function CriteriaGroupSectionFromJSON(json: any): CriteriaGroupSection {
    return CriteriaGroupSectionFromJSONTyped(json, false);
}

export function CriteriaGroupSectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): CriteriaGroupSection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'displayName': json['displayName'],
        'criteriaGroups': ((json['criteriaGroups'] as Array<any>).map(CriteriaGroupFromJSON)),
        'firstBlockReducingOperator': !exists(json, 'firstBlockReducingOperator') ? undefined : ReducingOperatorFromJSON(json['firstBlockReducingOperator']),
        'secondBlockCriteriaGroups': !exists(json, 'secondBlockCriteriaGroups') ? undefined : ((json['secondBlockCriteriaGroups'] as Array<any>).map(CriteriaGroupFromJSON)),
        'secondBlockReducingOperator': !exists(json, 'secondBlockReducingOperator') ? undefined : ReducingOperatorFromJSON(json['secondBlockReducingOperator']),
        'operator': json['operator'],
        'operatorValue': !exists(json, 'operatorValue') ? undefined : json['operatorValue'],
        'excluded': json['excluded'],
        'disabled': !exists(json, 'disabled') ? undefined : json['disabled'],
    };
}

export function CriteriaGroupSectionToJSON(value?: CriteriaGroupSection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'displayName': value.displayName,
        'criteriaGroups': ((value.criteriaGroups as Array<any>).map(CriteriaGroupToJSON)),
        'firstBlockReducingOperator': ReducingOperatorToJSON(value.firstBlockReducingOperator),
        'secondBlockCriteriaGroups': value.secondBlockCriteriaGroups === undefined ? undefined : ((value.secondBlockCriteriaGroups as Array<any>).map(CriteriaGroupToJSON)),
        'secondBlockReducingOperator': ReducingOperatorToJSON(value.secondBlockReducingOperator),
        'operator': value.operator,
        'operatorValue': value.operatorValue,
        'excluded': value.excluded,
        'disabled': value.disabled,
    };
}


