/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Filter,
    FilterFromJSON,
    FilterFromJSONTyped,
    FilterToJSON,
} from './';

/**
 * Count entity instances
 * @export
 * @interface CountQuery
 */
export interface CountQuery {
    /**
     * Attributes to group by. One count will be returned for each possible combination of attribute values 
     * (e.g. [gender, hair_color] will return counts for man+red, man+black, woman+red, woman+black).
     * @type {Array<string>}
     * @memberof CountQuery
     */
    attributes?: Array<string>;
    /**
     * 
     * @type {Filter}
     * @memberof CountQuery
     */
    filter?: Filter | null;
    /**
     * 
     * @type {number}
     * @memberof CountQuery
     */
    pageSize?: number;
    /**
     * 
     * @type {string}
     * @memberof CountQuery
     */
    pageMarker?: string;
}

export function CountQueryFromJSON(json: any): CountQuery {
    return CountQueryFromJSONTyped(json, false);
}

export function CountQueryFromJSONTyped(json: any, ignoreDiscriminator: boolean): CountQuery {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attributes': !exists(json, 'attributes') ? undefined : json['attributes'],
        'filter': !exists(json, 'filter') ? undefined : FilterFromJSON(json['filter']),
        'pageSize': !exists(json, 'pageSize') ? undefined : json['pageSize'],
        'pageMarker': !exists(json, 'pageMarker') ? undefined : json['pageMarker'],
    };
}

export function CountQueryToJSON(value?: CountQuery | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attributes': value.attributes,
        'filter': FilterToJSON(value.filter),
        'pageSize': value.pageSize,
        'pageMarker': value.pageMarker,
    };
}


