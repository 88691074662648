/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Related entity and optional hierarchies. Per-node rollups are always returned.
 * @export
 * @interface QueryIncludeRelationshipFields
 */
export interface QueryIncludeRelationshipFields {
    /**
     * 
     * @type {string}
     * @memberof QueryIncludeRelationshipFields
     */
    relatedEntity?: string;
    /**
     * Hierarchy names
     * @type {Array<string>}
     * @memberof QueryIncludeRelationshipFields
     */
    hierarchies?: Array<string>;
}

export function QueryIncludeRelationshipFieldsFromJSON(json: any): QueryIncludeRelationshipFields {
    return QueryIncludeRelationshipFieldsFromJSONTyped(json, false);
}

export function QueryIncludeRelationshipFieldsFromJSONTyped(json: any, ignoreDiscriminator: boolean): QueryIncludeRelationshipFields {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'relatedEntity': !exists(json, 'relatedEntity') ? undefined : json['relatedEntity'],
        'hierarchies': !exists(json, 'hierarchies') ? undefined : json['hierarchies'],
    };
}

export function QueryIncludeRelationshipFieldsToJSON(value?: QueryIncludeRelationshipFields | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'relatedEntity': value.relatedEntity,
        'hierarchies': value.hierarchies,
    };
}


