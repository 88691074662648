/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DataType,
    DataTypeFromJSON,
    DataTypeFromJSONTyped,
    DataTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface AnnotationCreateInfo
 */
export interface AnnotationCreateInfo {
    /**
     * Human readable name of the annotation
     * @type {string}
     * @memberof AnnotationCreateInfo
     */
    displayName: string;
    /**
     * Description of the annotation
     * @type {string}
     * @memberof AnnotationCreateInfo
     */
    description?: string;
    /**
     * 
     * @type {DataType}
     * @memberof AnnotationCreateInfo
     */
    dataType: DataType;
    /**
     * May only be set if dataType=STRING. Annotation value must be one of
     * enumVals.
     * @type {Array<string>}
     * @memberof AnnotationCreateInfo
     */
    enumVals?: Array<string>;
}

export function AnnotationCreateInfoFromJSON(json: any): AnnotationCreateInfo {
    return AnnotationCreateInfoFromJSONTyped(json, false);
}

export function AnnotationCreateInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AnnotationCreateInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'displayName': json['displayName'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'dataType': DataTypeFromJSON(json['dataType']),
        'enumVals': !exists(json, 'enumVals') ? undefined : json['enumVals'],
    };
}

export function AnnotationCreateInfoToJSON(value?: AnnotationCreateInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'displayName': value.displayName,
        'description': value.description,
        'dataType': DataTypeToJSON(value.dataType),
        'enumVals': value.enumVals,
    };
}


