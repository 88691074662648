/* eslint-disable */
import _m0 from "protobufjs/minimal";

export const protobufPackage = "tanagra.configschema";

/** A criteria that allows searching for text across categorized items. */
export interface TextSearch {
  /** The entity to search. */
  entity: string;
  /**
   * The attribute to search within. Defaults to the search configuration in the
   * entity definition.
   */
  searchAttribute?:
    | string
    | undefined;
  /** An optional categorical attribute to additionally filter on. */
  categoryAttribute?: string | undefined;
}

function createBaseTextSearch(): TextSearch {
  return { entity: "", searchAttribute: undefined, categoryAttribute: undefined };
}

export const TextSearch = {
  encode(message: TextSearch, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.entity !== "") {
      writer.uint32(10).string(message.entity);
    }
    if (message.searchAttribute !== undefined) {
      writer.uint32(18).string(message.searchAttribute);
    }
    if (message.categoryAttribute !== undefined) {
      writer.uint32(26).string(message.categoryAttribute);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TextSearch {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTextSearch();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.entity = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.searchAttribute = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.categoryAttribute = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TextSearch {
    return {
      entity: isSet(object.entity) ? globalThis.String(object.entity) : "",
      searchAttribute: isSet(object.searchAttribute) ? globalThis.String(object.searchAttribute) : undefined,
      categoryAttribute: isSet(object.categoryAttribute) ? globalThis.String(object.categoryAttribute) : undefined,
    };
  },

  toJSON(message: TextSearch): unknown {
    const obj: any = {};
    if (message.entity !== "") {
      obj.entity = message.entity;
    }
    if (message.searchAttribute !== undefined) {
      obj.searchAttribute = message.searchAttribute;
    }
    if (message.categoryAttribute !== undefined) {
      obj.categoryAttribute = message.categoryAttribute;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TextSearch>, I>>(base?: I): TextSearch {
    return TextSearch.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TextSearch>, I>>(object: I): TextSearch {
    const message = createBaseTextSearch();
    message.entity = object.entity ?? "";
    message.searchAttribute = object.searchAttribute ?? undefined;
    message.categoryAttribute = object.categoryAttribute ?? undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
