/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PropertyKeyValue,
    PropertyKeyValueFromJSON,
    PropertyKeyValueFromJSONTyped,
    PropertyKeyValueToJSON,
} from './';

/**
 * 
 * @export
 * @interface StudyCreateInfo
 */
export interface StudyCreateInfo {
    /**
     * ID of the study, immutable
     * @type {string}
     * @memberof StudyCreateInfo
     */
    id?: string;
    /**
     * Human readable name of the study
     * @type {string}
     * @memberof StudyCreateInfo
     */
    displayName?: string;
    /**
     * Description of the study
     * @type {string}
     * @memberof StudyCreateInfo
     */
    description?: string;
    /**
     * List of key-value pairs of strings
     * @type {Array<PropertyKeyValue>}
     * @memberof StudyCreateInfo
     */
    properties?: Array<PropertyKeyValue>;
}

export function StudyCreateInfoFromJSON(json: any): StudyCreateInfo {
    return StudyCreateInfoFromJSONTyped(json, false);
}

export function StudyCreateInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StudyCreateInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'displayName': !exists(json, 'displayName') ? undefined : json['displayName'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'properties': !exists(json, 'properties') ? undefined : ((json['properties'] as Array<any>).map(PropertyKeyValueFromJSON)),
    };
}

export function StudyCreateInfoToJSON(value?: StudyCreateInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'displayName': value.displayName,
        'description': value.description,
        'properties': value.properties === undefined ? undefined : ((value.properties as Array<any>).map(PropertyKeyValueToJSON)),
    };
}


