/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Entity,
    EntityFromJSON,
    EntityFromJSONTyped,
    EntityToJSON,
} from './';

/**
 * List of entities
 * @export
 * @interface EntityList
 */
export interface EntityList {
    /**
     * 
     * @type {Array<Entity>}
     * @memberof EntityList
     */
    entities?: Array<Entity>;
}

export function EntityListFromJSON(json: any): EntityList {
    return EntityListFromJSONTyped(json, false);
}

export function EntityListFromJSONTyped(json: any, ignoreDiscriminator: boolean): EntityList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entities': !exists(json, 'entities') ? undefined : ((json['entities'] as Array<any>).map(EntityFromJSON)),
    };
}

export function EntityListToJSON(value?: EntityList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entities': value.entities === undefined ? undefined : ((value.entities as Array<any>).map(EntityToJSON)),
    };
}


