/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Filter,
    FilterFromJSON,
    FilterFromJSONTyped,
    FilterToJSON,
} from './';

/**
 * 
 * @export
 * @interface PrimaryWithCriteriaFilterOccurrenceSubfiltersAndGroupByAttributes
 */
export interface PrimaryWithCriteriaFilterOccurrenceSubfiltersAndGroupByAttributes {
    /**
     * 
     * @type {Array<Filter>}
     * @memberof PrimaryWithCriteriaFilterOccurrenceSubfiltersAndGroupByAttributes
     */
    subfilters?: Array<Filter>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PrimaryWithCriteriaFilterOccurrenceSubfiltersAndGroupByAttributes
     */
    groupByCountAttributes?: Array<string>;
}

export function PrimaryWithCriteriaFilterOccurrenceSubfiltersAndGroupByAttributesFromJSON(json: any): PrimaryWithCriteriaFilterOccurrenceSubfiltersAndGroupByAttributes {
    return PrimaryWithCriteriaFilterOccurrenceSubfiltersAndGroupByAttributesFromJSONTyped(json, false);
}

export function PrimaryWithCriteriaFilterOccurrenceSubfiltersAndGroupByAttributesFromJSONTyped(json: any, ignoreDiscriminator: boolean): PrimaryWithCriteriaFilterOccurrenceSubfiltersAndGroupByAttributes {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'subfilters': !exists(json, 'subfilters') ? undefined : ((json['subfilters'] as Array<any>).map(FilterFromJSON)),
        'groupByCountAttributes': !exists(json, 'group_by_count_attributes') ? undefined : json['group_by_count_attributes'],
    };
}

export function PrimaryWithCriteriaFilterOccurrenceSubfiltersAndGroupByAttributesToJSON(value?: PrimaryWithCriteriaFilterOccurrenceSubfiltersAndGroupByAttributes | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'subfilters': value.subfilters === undefined ? undefined : ((value.subfilters as Array<any>).map(FilterToJSON)),
        'group_by_count_attributes': value.groupByCountAttributes,
    };
}


