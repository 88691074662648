/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CohortCloneInfo
 */
export interface CohortCloneInfo {
    /**
     * ID of the study, immutable
     * @type {string}
     * @memberof CohortCloneInfo
     */
    destinationStudyId?: string;
    /**
     * Human readable name of the cohort
     * @type {string}
     * @memberof CohortCloneInfo
     */
    displayName?: string;
    /**
     * Description of the cohort
     * @type {string}
     * @memberof CohortCloneInfo
     */
    description?: string;
}

export function CohortCloneInfoFromJSON(json: any): CohortCloneInfo {
    return CohortCloneInfoFromJSONTyped(json, false);
}

export function CohortCloneInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CohortCloneInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'destinationStudyId': !exists(json, 'destinationStudyId') ? undefined : json['destinationStudyId'],
        'displayName': !exists(json, 'displayName') ? undefined : json['displayName'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function CohortCloneInfoToJSON(value?: CohortCloneInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'destinationStudyId': value.destinationStudyId,
        'displayName': value.displayName,
        'description': value.description,
    };
}


