/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InstanceHierarchyFields
 */
export interface InstanceHierarchyFields {
    /**
     * 
     * @type {string}
     * @memberof InstanceHierarchyFields
     */
    hierarchy?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InstanceHierarchyFields
     */
    isMember?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InstanceHierarchyFields
     */
    path?: string;
    /**
     * 
     * @type {number}
     * @memberof InstanceHierarchyFields
     */
    numChildren?: number;
    /**
     * 
     * @type {boolean}
     * @memberof InstanceHierarchyFields
     */
    isRoot?: boolean;
}

export function InstanceHierarchyFieldsFromJSON(json: any): InstanceHierarchyFields {
    return InstanceHierarchyFieldsFromJSONTyped(json, false);
}

export function InstanceHierarchyFieldsFromJSONTyped(json: any, ignoreDiscriminator: boolean): InstanceHierarchyFields {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'hierarchy': !exists(json, 'hierarchy') ? undefined : json['hierarchy'],
        'isMember': !exists(json, 'isMember') ? undefined : json['isMember'],
        'path': !exists(json, 'path') ? undefined : json['path'],
        'numChildren': !exists(json, 'numChildren') ? undefined : json['numChildren'],
        'isRoot': !exists(json, 'isRoot') ? undefined : json['isRoot'],
    };
}

export function InstanceHierarchyFieldsToJSON(value?: InstanceHierarchyFields | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'hierarchy': value.hierarchy,
        'isMember': value.isMember,
        'path': value.path,
        'numChildren': value.numChildren,
        'isRoot': value.isRoot,
    };
}


