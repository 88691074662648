/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BinaryOperator,
    BinaryOperatorFromJSON,
    BinaryOperatorFromJSONTyped,
    BinaryOperatorToJSON,
    Filter,
    FilterFromJSON,
    FilterFromJSONTyped,
    FilterToJSON,
} from './';

/**
 * Filter an items entity with a group-items entity group. (e.g. ingredients in a brand,
 * blood pressure readings for a person).
 * If the groupByCountOperator is set, then the groupByCountValue must also be set.
 * The groupByCountAttribute is optional. If undefined, we group by the id only.
 * When group by fields are set, we filter on the number of related entity instances.
 * e.g. filter ingredients that are included in >2 brands
 * @export
 * @interface ItemInGroupFilter
 */
export interface ItemInGroupFilter {
    /**
     * 
     * @type {string}
     * @memberof ItemInGroupFilter
     */
    entityGroup: string;
    /**
     * 
     * @type {Filter}
     * @memberof ItemInGroupFilter
     */
    groupSubfilter?: Filter | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ItemInGroupFilter
     */
    groupByCountAttributes?: Array<string>;
    /**
     * 
     * @type {BinaryOperator}
     * @memberof ItemInGroupFilter
     */
    groupByCountOperator?: BinaryOperator;
    /**
     * 
     * @type {number}
     * @memberof ItemInGroupFilter
     */
    groupByCountValue?: number;
}

export function ItemInGroupFilterFromJSON(json: any): ItemInGroupFilter {
    return ItemInGroupFilterFromJSONTyped(json, false);
}

export function ItemInGroupFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): ItemInGroupFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entityGroup': json['entityGroup'],
        'groupSubfilter': !exists(json, 'groupSubfilter') ? undefined : FilterFromJSON(json['groupSubfilter']),
        'groupByCountAttributes': !exists(json, 'groupByCountAttributes') ? undefined : json['groupByCountAttributes'],
        'groupByCountOperator': !exists(json, 'groupByCountOperator') ? undefined : BinaryOperatorFromJSON(json['groupByCountOperator']),
        'groupByCountValue': !exists(json, 'groupByCountValue') ? undefined : json['groupByCountValue'],
    };
}

export function ItemInGroupFilterToJSON(value?: ItemInGroupFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entityGroup': value.entityGroup,
        'groupSubfilter': FilterToJSON(value.groupSubfilter),
        'groupByCountAttributes': value.groupByCountAttributes,
        'groupByCountOperator': BinaryOperatorToJSON(value.groupByCountOperator),
        'groupByCountValue': value.groupByCountValue,
    };
}


