/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DisplayHintEnumEnumHintValues,
    DisplayHintEnumEnumHintValuesFromJSON,
    DisplayHintEnumEnumHintValuesFromJSONTyped,
    DisplayHintEnumEnumHintValuesToJSON,
} from './';

/**
 * Enumeration of possible values, display strings, and counts
 * @export
 * @interface DisplayHintEnum
 */
export interface DisplayHintEnum {
    /**
     * 
     * @type {Array<DisplayHintEnumEnumHintValues>}
     * @memberof DisplayHintEnum
     */
    enumHintValues?: Array<DisplayHintEnumEnumHintValues>;
}

export function DisplayHintEnumFromJSON(json: any): DisplayHintEnum {
    return DisplayHintEnumFromJSONTyped(json, false);
}

export function DisplayHintEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): DisplayHintEnum {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'enumHintValues': !exists(json, 'enumHintValues') ? undefined : ((json['enumHintValues'] as Array<any>).map(DisplayHintEnumEnumHintValuesFromJSON)),
    };
}

export function DisplayHintEnumToJSON(value?: DisplayHintEnum | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'enumHintValues': value.enumHintValues === undefined ? undefined : ((value.enumHintValues as Array<any>).map(DisplayHintEnumEnumHintValuesToJSON)),
    };
}


