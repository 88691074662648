/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Reducing operator for criteria groups blocks.
 * Only applies for a temporal section.
 * @export
 * @enum {string}
 */
export enum ReducingOperator {
    Any = 'ANY',
    FirstMentionOf = 'FIRST_MENTION_OF',
    LastMentionOf = 'LAST_MENTION_OF'
}

export function ReducingOperatorFromJSON(json: any): ReducingOperator {
    return ReducingOperatorFromJSONTyped(json, false);
}

export function ReducingOperatorFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReducingOperator {
    return json as ReducingOperator;
}

export function ReducingOperatorToJSON(value?: ReducingOperator | null): any {
    return value as any;
}

