/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BinaryOperator,
    BinaryOperatorFromJSON,
    BinaryOperatorFromJSONTyped,
    BinaryOperatorToJSON,
    Filter,
    FilterFromJSON,
    FilterFromJSONTyped,
    FilterToJSON,
} from './';

/**
 * Filter on a relationship between entities (e.g. condition occurrences where condition=diabetes).
 * If the group_by_count_operator is set, then the group_by_count_value must also be set.
 * The group_by_count_attribute is optional. If undefined, we group by the id only.
 * When group_by fields are set, we filter on # of related entity instances.
 * e.g. filter person instances with >2 related condition_occurrence instances
 * @export
 * @interface RelationshipFilter
 */
export interface RelationshipFilter {
    /**
     * 
     * @type {string}
     * @memberof RelationshipFilter
     */
    entity?: string;
    /**
     * 
     * @type {Filter}
     * @memberof RelationshipFilter
     */
    subfilter?: Filter | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RelationshipFilter
     */
    groupByCountAttributes?: Array<string>;
    /**
     * 
     * @type {BinaryOperator}
     * @memberof RelationshipFilter
     */
    groupByCountOperator?: BinaryOperator;
    /**
     * 
     * @type {number}
     * @memberof RelationshipFilter
     */
    groupByCountValue?: number | null;
}

export function RelationshipFilterFromJSON(json: any): RelationshipFilter {
    return RelationshipFilterFromJSONTyped(json, false);
}

export function RelationshipFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): RelationshipFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entity': !exists(json, 'entity') ? undefined : json['entity'],
        'subfilter': !exists(json, 'subfilter') ? undefined : FilterFromJSON(json['subfilter']),
        'groupByCountAttributes': !exists(json, 'groupByCountAttributes') ? undefined : json['groupByCountAttributes'],
        'groupByCountOperator': !exists(json, 'groupByCountOperator') ? undefined : BinaryOperatorFromJSON(json['groupByCountOperator']),
        'groupByCountValue': !exists(json, 'groupByCountValue') ? undefined : json['groupByCountValue'],
    };
}

export function RelationshipFilterToJSON(value?: RelationshipFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entity': value.entity,
        'subfilter': FilterToJSON(value.subfilter),
        'groupByCountAttributes': value.groupByCountAttributes,
        'groupByCountOperator': BinaryOperatorToJSON(value.groupByCountOperator),
        'groupByCountValue': value.groupByCountValue,
    };
}


