/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrderByDirection,
    OrderByDirectionFromJSON,
    OrderByDirectionFromJSONTyped,
    OrderByDirectionToJSON,
} from './';

/**
 * Attribute field and the direction
 * @export
 * @interface QueryFilterOnPrimaryEntityOrderBys
 */
export interface QueryFilterOnPrimaryEntityOrderBys {
    /**
     * 
     * @type {string}
     * @memberof QueryFilterOnPrimaryEntityOrderBys
     */
    attribute?: string;
    /**
     * 
     * @type {OrderByDirection}
     * @memberof QueryFilterOnPrimaryEntityOrderBys
     */
    direction?: OrderByDirection;
}

export function QueryFilterOnPrimaryEntityOrderBysFromJSON(json: any): QueryFilterOnPrimaryEntityOrderBys {
    return QueryFilterOnPrimaryEntityOrderBysFromJSONTyped(json, false);
}

export function QueryFilterOnPrimaryEntityOrderBysFromJSONTyped(json: any, ignoreDiscriminator: boolean): QueryFilterOnPrimaryEntityOrderBys {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attribute': !exists(json, 'attribute') ? undefined : json['attribute'],
        'direction': !exists(json, 'direction') ? undefined : OrderByDirectionFromJSON(json['direction']),
    };
}

export function QueryFilterOnPrimaryEntityOrderBysToJSON(value?: QueryFilterOnPrimaryEntityOrderBys | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attribute': value.attribute,
        'direction': OrderByDirectionToJSON(value.direction),
    };
}


