/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Annotation,
    AnnotationFromJSON,
    AnnotationToJSON,
    AnnotationCreateInfo,
    AnnotationCreateInfoFromJSON,
    AnnotationCreateInfoToJSON,
    AnnotationUpdateInfo,
    AnnotationUpdateInfoFromJSON,
    AnnotationUpdateInfoToJSON,
    ErrorReport,
    ErrorReportFromJSON,
    ErrorReportToJSON,
    Literal,
    LiteralFromJSON,
    LiteralToJSON,
} from '../models';

export interface CreateAnnotationKeyRequest {
    studyId: string;
    cohortId: string;
    annotationCreateInfo: AnnotationCreateInfo;
}

export interface DeleteAnnotationKeyRequest {
    studyId: string;
    cohortId: string;
    annotationId: string;
}

export interface DeleteAnnotationValuesRequest {
    studyId: string;
    cohortId: string;
    annotationId: string;
    reviewId: string;
    instanceId: string;
}

export interface GetAnnotationKeyRequest {
    studyId: string;
    cohortId: string;
    annotationId: string;
}

export interface ListAnnotationKeysRequest {
    studyId: string;
    cohortId: string;
    offset?: number;
    limit?: number;
}

export interface UpdateAnnotationKeyRequest {
    studyId: string;
    cohortId: string;
    annotationId: string;
    annotationUpdateInfo: AnnotationUpdateInfo;
}

export interface UpdateAnnotationValueRequest {
    studyId: string;
    cohortId: string;
    annotationId: string;
    reviewId: string;
    instanceId: string;
    literal: Literal | null;
}

/**
 * 
 */
export class AnnotationsApi extends runtime.BaseAPI {

    /**
     * Create a new annotation key
     */
    async createAnnotationKeyRaw(requestParameters: CreateAnnotationKeyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Annotation>> {
        if (requestParameters.studyId === null || requestParameters.studyId === undefined) {
            throw new runtime.RequiredError('studyId','Required parameter requestParameters.studyId was null or undefined when calling createAnnotationKey.');
        }

        if (requestParameters.cohortId === null || requestParameters.cohortId === undefined) {
            throw new runtime.RequiredError('cohortId','Required parameter requestParameters.cohortId was null or undefined when calling createAnnotationKey.');
        }

        if (requestParameters.annotationCreateInfo === null || requestParameters.annotationCreateInfo === undefined) {
            throw new runtime.RequiredError('annotationCreateInfo','Required parameter requestParameters.annotationCreateInfo was null or undefined when calling createAnnotationKey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("authorization", ["openid", "email", "profile"]);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/studies/{studyId}/cohorts/{cohortId}/annotations`.replace(`{${"studyId"}}`, encodeURIComponent(String(requestParameters.studyId))).replace(`{${"cohortId"}}`, encodeURIComponent(String(requestParameters.cohortId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AnnotationCreateInfoToJSON(requestParameters.annotationCreateInfo),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AnnotationFromJSON(jsonValue));
    }

    /**
     * Create a new annotation key
     */
    async createAnnotationKey(requestParameters: CreateAnnotationKeyRequest, initOverrides?: RequestInit): Promise<Annotation> {
        const response = await this.createAnnotationKeyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete an annotation key
     */
    async deleteAnnotationKeyRaw(requestParameters: DeleteAnnotationKeyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.studyId === null || requestParameters.studyId === undefined) {
            throw new runtime.RequiredError('studyId','Required parameter requestParameters.studyId was null or undefined when calling deleteAnnotationKey.');
        }

        if (requestParameters.cohortId === null || requestParameters.cohortId === undefined) {
            throw new runtime.RequiredError('cohortId','Required parameter requestParameters.cohortId was null or undefined when calling deleteAnnotationKey.');
        }

        if (requestParameters.annotationId === null || requestParameters.annotationId === undefined) {
            throw new runtime.RequiredError('annotationId','Required parameter requestParameters.annotationId was null or undefined when calling deleteAnnotationKey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("authorization", ["openid", "email", "profile"]);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/studies/{studyId}/cohorts/{cohortId}/annotations/{annotationId}`.replace(`{${"studyId"}}`, encodeURIComponent(String(requestParameters.studyId))).replace(`{${"cohortId"}}`, encodeURIComponent(String(requestParameters.cohortId))).replace(`{${"annotationId"}}`, encodeURIComponent(String(requestParameters.annotationId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete an annotation key
     */
    async deleteAnnotationKey(requestParameters: DeleteAnnotationKeyRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteAnnotationKeyRaw(requestParameters, initOverrides);
    }

    /**
     * Delete an annotation value
     */
    async deleteAnnotationValuesRaw(requestParameters: DeleteAnnotationValuesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.studyId === null || requestParameters.studyId === undefined) {
            throw new runtime.RequiredError('studyId','Required parameter requestParameters.studyId was null or undefined when calling deleteAnnotationValues.');
        }

        if (requestParameters.cohortId === null || requestParameters.cohortId === undefined) {
            throw new runtime.RequiredError('cohortId','Required parameter requestParameters.cohortId was null or undefined when calling deleteAnnotationValues.');
        }

        if (requestParameters.annotationId === null || requestParameters.annotationId === undefined) {
            throw new runtime.RequiredError('annotationId','Required parameter requestParameters.annotationId was null or undefined when calling deleteAnnotationValues.');
        }

        if (requestParameters.reviewId === null || requestParameters.reviewId === undefined) {
            throw new runtime.RequiredError('reviewId','Required parameter requestParameters.reviewId was null or undefined when calling deleteAnnotationValues.');
        }

        if (requestParameters.instanceId === null || requestParameters.instanceId === undefined) {
            throw new runtime.RequiredError('instanceId','Required parameter requestParameters.instanceId was null or undefined when calling deleteAnnotationValues.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("authorization", ["openid", "email", "profile"]);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/studies/{studyId}/cohorts/{cohortId}/annotations/{annotationId}/reviews/{reviewId}/values/{instanceId}`.replace(`{${"studyId"}}`, encodeURIComponent(String(requestParameters.studyId))).replace(`{${"cohortId"}}`, encodeURIComponent(String(requestParameters.cohortId))).replace(`{${"annotationId"}}`, encodeURIComponent(String(requestParameters.annotationId))).replace(`{${"reviewId"}}`, encodeURIComponent(String(requestParameters.reviewId))).replace(`{${"instanceId"}}`, encodeURIComponent(String(requestParameters.instanceId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete an annotation value
     */
    async deleteAnnotationValues(requestParameters: DeleteAnnotationValuesRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteAnnotationValuesRaw(requestParameters, initOverrides);
    }

    /**
     * Get an existing annotation key
     */
    async getAnnotationKeyRaw(requestParameters: GetAnnotationKeyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Annotation>> {
        if (requestParameters.studyId === null || requestParameters.studyId === undefined) {
            throw new runtime.RequiredError('studyId','Required parameter requestParameters.studyId was null or undefined when calling getAnnotationKey.');
        }

        if (requestParameters.cohortId === null || requestParameters.cohortId === undefined) {
            throw new runtime.RequiredError('cohortId','Required parameter requestParameters.cohortId was null or undefined when calling getAnnotationKey.');
        }

        if (requestParameters.annotationId === null || requestParameters.annotationId === undefined) {
            throw new runtime.RequiredError('annotationId','Required parameter requestParameters.annotationId was null or undefined when calling getAnnotationKey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("authorization", ["openid", "email", "profile"]);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/studies/{studyId}/cohorts/{cohortId}/annotations/{annotationId}`.replace(`{${"studyId"}}`, encodeURIComponent(String(requestParameters.studyId))).replace(`{${"cohortId"}}`, encodeURIComponent(String(requestParameters.cohortId))).replace(`{${"annotationId"}}`, encodeURIComponent(String(requestParameters.annotationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AnnotationFromJSON(jsonValue));
    }

    /**
     * Get an existing annotation key
     */
    async getAnnotationKey(requestParameters: GetAnnotationKeyRequest, initOverrides?: RequestInit): Promise<Annotation> {
        const response = await this.getAnnotationKeyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all annotation keys for a cohort
     */
    async listAnnotationKeysRaw(requestParameters: ListAnnotationKeysRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Annotation>>> {
        if (requestParameters.studyId === null || requestParameters.studyId === undefined) {
            throw new runtime.RequiredError('studyId','Required parameter requestParameters.studyId was null or undefined when calling listAnnotationKeys.');
        }

        if (requestParameters.cohortId === null || requestParameters.cohortId === undefined) {
            throw new runtime.RequiredError('cohortId','Required parameter requestParameters.cohortId was null or undefined when calling listAnnotationKeys.');
        }

        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("authorization", ["openid", "email", "profile"]);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/studies/{studyId}/cohorts/{cohortId}/annotations`.replace(`{${"studyId"}}`, encodeURIComponent(String(requestParameters.studyId))).replace(`{${"cohortId"}}`, encodeURIComponent(String(requestParameters.cohortId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AnnotationFromJSON));
    }

    /**
     * List all annotation keys for a cohort
     */
    async listAnnotationKeys(requestParameters: ListAnnotationKeysRequest, initOverrides?: RequestInit): Promise<Array<Annotation>> {
        const response = await this.listAnnotationKeysRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update an existing annotation key
     */
    async updateAnnotationKeyRaw(requestParameters: UpdateAnnotationKeyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Annotation>> {
        if (requestParameters.studyId === null || requestParameters.studyId === undefined) {
            throw new runtime.RequiredError('studyId','Required parameter requestParameters.studyId was null or undefined when calling updateAnnotationKey.');
        }

        if (requestParameters.cohortId === null || requestParameters.cohortId === undefined) {
            throw new runtime.RequiredError('cohortId','Required parameter requestParameters.cohortId was null or undefined when calling updateAnnotationKey.');
        }

        if (requestParameters.annotationId === null || requestParameters.annotationId === undefined) {
            throw new runtime.RequiredError('annotationId','Required parameter requestParameters.annotationId was null or undefined when calling updateAnnotationKey.');
        }

        if (requestParameters.annotationUpdateInfo === null || requestParameters.annotationUpdateInfo === undefined) {
            throw new runtime.RequiredError('annotationUpdateInfo','Required parameter requestParameters.annotationUpdateInfo was null or undefined when calling updateAnnotationKey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("authorization", ["openid", "email", "profile"]);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/studies/{studyId}/cohorts/{cohortId}/annotations/{annotationId}`.replace(`{${"studyId"}}`, encodeURIComponent(String(requestParameters.studyId))).replace(`{${"cohortId"}}`, encodeURIComponent(String(requestParameters.cohortId))).replace(`{${"annotationId"}}`, encodeURIComponent(String(requestParameters.annotationId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: AnnotationUpdateInfoToJSON(requestParameters.annotationUpdateInfo),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AnnotationFromJSON(jsonValue));
    }

    /**
     * Update an existing annotation key
     */
    async updateAnnotationKey(requestParameters: UpdateAnnotationKeyRequest, initOverrides?: RequestInit): Promise<Annotation> {
        const response = await this.updateAnnotationKeyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create or update an annotation value
     */
    async updateAnnotationValueRaw(requestParameters: UpdateAnnotationValueRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.studyId === null || requestParameters.studyId === undefined) {
            throw new runtime.RequiredError('studyId','Required parameter requestParameters.studyId was null or undefined when calling updateAnnotationValue.');
        }

        if (requestParameters.cohortId === null || requestParameters.cohortId === undefined) {
            throw new runtime.RequiredError('cohortId','Required parameter requestParameters.cohortId was null or undefined when calling updateAnnotationValue.');
        }

        if (requestParameters.annotationId === null || requestParameters.annotationId === undefined) {
            throw new runtime.RequiredError('annotationId','Required parameter requestParameters.annotationId was null or undefined when calling updateAnnotationValue.');
        }

        if (requestParameters.reviewId === null || requestParameters.reviewId === undefined) {
            throw new runtime.RequiredError('reviewId','Required parameter requestParameters.reviewId was null or undefined when calling updateAnnotationValue.');
        }

        if (requestParameters.instanceId === null || requestParameters.instanceId === undefined) {
            throw new runtime.RequiredError('instanceId','Required parameter requestParameters.instanceId was null or undefined when calling updateAnnotationValue.');
        }

        if (requestParameters.literal === null || requestParameters.literal === undefined) {
            throw new runtime.RequiredError('literal','Required parameter requestParameters.literal was null or undefined when calling updateAnnotationValue.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("authorization", ["openid", "email", "profile"]);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/studies/{studyId}/cohorts/{cohortId}/annotations/{annotationId}/reviews/{reviewId}/values/{instanceId}`.replace(`{${"studyId"}}`, encodeURIComponent(String(requestParameters.studyId))).replace(`{${"cohortId"}}`, encodeURIComponent(String(requestParameters.cohortId))).replace(`{${"annotationId"}}`, encodeURIComponent(String(requestParameters.annotationId))).replace(`{${"reviewId"}}`, encodeURIComponent(String(requestParameters.reviewId))).replace(`{${"instanceId"}}`, encodeURIComponent(String(requestParameters.instanceId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LiteralToJSON(requestParameters.literal),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create or update an annotation value
     */
    async updateAnnotationValue(requestParameters: UpdateAnnotationValueRequest, initOverrides?: RequestInit): Promise<void> {
        await this.updateAnnotationValueRaw(requestParameters, initOverrides);
    }

}
