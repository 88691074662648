/* eslint-disable */
import _m0 from "protobufjs/minimal";

export const protobufPackage = "tanagra.dataschema";

export interface UnhintedValue {
  operator: UnhintedValue_ComparisonOperator;
  min: number;
  max: number;
}

export enum UnhintedValue_ComparisonOperator {
  COMPARISON_OPERATOR_UNKNOWN = 0,
  COMPARISON_OPERATOR_EQUAL = 1,
  COMPARISON_OPERATOR_BETWEEN = 2,
  COMPARISON_OPERATOR_LESS_THAN_EQUAL = 3,
  COMPARISON_OPERATOR_GREATER_THAN_EQUAL = 4,
  UNRECOGNIZED = -1,
}

export function unhintedValue_ComparisonOperatorFromJSON(object: any): UnhintedValue_ComparisonOperator {
  switch (object) {
    case 0:
    case "COMPARISON_OPERATOR_UNKNOWN":
      return UnhintedValue_ComparisonOperator.COMPARISON_OPERATOR_UNKNOWN;
    case 1:
    case "COMPARISON_OPERATOR_EQUAL":
      return UnhintedValue_ComparisonOperator.COMPARISON_OPERATOR_EQUAL;
    case 2:
    case "COMPARISON_OPERATOR_BETWEEN":
      return UnhintedValue_ComparisonOperator.COMPARISON_OPERATOR_BETWEEN;
    case 3:
    case "COMPARISON_OPERATOR_LESS_THAN_EQUAL":
      return UnhintedValue_ComparisonOperator.COMPARISON_OPERATOR_LESS_THAN_EQUAL;
    case 4:
    case "COMPARISON_OPERATOR_GREATER_THAN_EQUAL":
      return UnhintedValue_ComparisonOperator.COMPARISON_OPERATOR_GREATER_THAN_EQUAL;
    case -1:
    case "UNRECOGNIZED":
    default:
      return UnhintedValue_ComparisonOperator.UNRECOGNIZED;
  }
}

export function unhintedValue_ComparisonOperatorToJSON(object: UnhintedValue_ComparisonOperator): string {
  switch (object) {
    case UnhintedValue_ComparisonOperator.COMPARISON_OPERATOR_UNKNOWN:
      return "COMPARISON_OPERATOR_UNKNOWN";
    case UnhintedValue_ComparisonOperator.COMPARISON_OPERATOR_EQUAL:
      return "COMPARISON_OPERATOR_EQUAL";
    case UnhintedValue_ComparisonOperator.COMPARISON_OPERATOR_BETWEEN:
      return "COMPARISON_OPERATOR_BETWEEN";
    case UnhintedValue_ComparisonOperator.COMPARISON_OPERATOR_LESS_THAN_EQUAL:
      return "COMPARISON_OPERATOR_LESS_THAN_EQUAL";
    case UnhintedValue_ComparisonOperator.COMPARISON_OPERATOR_GREATER_THAN_EQUAL:
      return "COMPARISON_OPERATOR_GREATER_THAN_EQUAL";
    case UnhintedValue_ComparisonOperator.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

function createBaseUnhintedValue(): UnhintedValue {
  return { operator: 0, min: 0, max: 0 };
}

export const UnhintedValue = {
  encode(message: UnhintedValue, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.operator !== 0) {
      writer.uint32(8).int32(message.operator);
    }
    if (message.min !== 0) {
      writer.uint32(17).double(message.min);
    }
    if (message.max !== 0) {
      writer.uint32(25).double(message.max);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UnhintedValue {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUnhintedValue();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.operator = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.min = reader.double();
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.max = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UnhintedValue {
    return {
      operator: isSet(object.operator) ? unhintedValue_ComparisonOperatorFromJSON(object.operator) : 0,
      min: isSet(object.min) ? globalThis.Number(object.min) : 0,
      max: isSet(object.max) ? globalThis.Number(object.max) : 0,
    };
  },

  toJSON(message: UnhintedValue): unknown {
    const obj: any = {};
    if (message.operator !== 0) {
      obj.operator = unhintedValue_ComparisonOperatorToJSON(message.operator);
    }
    if (message.min !== 0) {
      obj.min = message.min;
    }
    if (message.max !== 0) {
      obj.max = message.max;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UnhintedValue>, I>>(base?: I): UnhintedValue {
    return UnhintedValue.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UnhintedValue>, I>>(object: I): UnhintedValue {
    const message = createBaseUnhintedValue();
    message.operator = object.operator ?? 0;
    message.min = object.min ?? 0;
    message.max = object.max ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
