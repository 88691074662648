/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ErrorReport,
    ErrorReportFromJSON,
    ErrorReportToJSON,
    PropertyKeyValue,
    PropertyKeyValueFromJSON,
    PropertyKeyValueToJSON,
    Study,
    StudyFromJSON,
    StudyToJSON,
    StudyCreateInfo,
    StudyCreateInfoFromJSON,
    StudyCreateInfoToJSON,
    StudyUpdateInfo,
    StudyUpdateInfoFromJSON,
    StudyUpdateInfoToJSON,
} from '../models';

export interface CreateStudyRequest {
    studyCreateInfo: StudyCreateInfo;
}

export interface DeleteStudyRequest {
    studyId: string;
}

export interface DeleteStudyPropertiesRequest {
    studyId: string;
    requestBody: Array<string>;
}

export interface GetStudyRequest {
    studyId: string;
}

export interface ListStudiesRequest {
    displayName?: string;
    description?: string;
    createdBy?: string;
    includeDeleted?: boolean;
    properties?: Array<string>;
    userAccessGroup?: string;
    offset?: number;
    limit?: number;
}

export interface UpdateStudyRequest {
    studyId: string;
    studyUpdateInfo: StudyUpdateInfo;
}

export interface UpdateStudyPropertiesRequest {
    studyId: string;
    propertyKeyValue: Array<PropertyKeyValue>;
}

/**
 * 
 */
export class StudiesApi extends runtime.BaseAPI {

    /**
     * Create a new study
     */
    async createStudyRaw(requestParameters: CreateStudyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Study>> {
        if (requestParameters.studyCreateInfo === null || requestParameters.studyCreateInfo === undefined) {
            throw new runtime.RequiredError('studyCreateInfo','Required parameter requestParameters.studyCreateInfo was null or undefined when calling createStudy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("authorization", ["openid", "email", "profile"]);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/studies`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StudyCreateInfoToJSON(requestParameters.studyCreateInfo),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StudyFromJSON(jsonValue));
    }

    /**
     * Create a new study
     */
    async createStudy(requestParameters: CreateStudyRequest, initOverrides?: RequestInit): Promise<Study> {
        const response = await this.createStudyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a study
     */
    async deleteStudyRaw(requestParameters: DeleteStudyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.studyId === null || requestParameters.studyId === undefined) {
            throw new runtime.RequiredError('studyId','Required parameter requestParameters.studyId was null or undefined when calling deleteStudy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("authorization", ["openid", "email", "profile"]);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/studies/{studyId}`.replace(`{${"studyId"}}`, encodeURIComponent(String(requestParameters.studyId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a study
     */
    async deleteStudy(requestParameters: DeleteStudyRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteStudyRaw(requestParameters, initOverrides);
    }

    /**
     * Delete properties in a study. Only properties with keys in request are deleted. Properties with keys not in request are not deleted. 
     */
    async deleteStudyPropertiesRaw(requestParameters: DeleteStudyPropertiesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.studyId === null || requestParameters.studyId === undefined) {
            throw new runtime.RequiredError('studyId','Required parameter requestParameters.studyId was null or undefined when calling deleteStudyProperties.');
        }

        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling deleteStudyProperties.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("authorization", ["openid", "email", "profile"]);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/studies/{studyId}/properties`.replace(`{${"studyId"}}`, encodeURIComponent(String(requestParameters.studyId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete properties in a study. Only properties with keys in request are deleted. Properties with keys not in request are not deleted. 
     */
    async deleteStudyProperties(requestParameters: DeleteStudyPropertiesRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteStudyPropertiesRaw(requestParameters, initOverrides);
    }

    /**
     * Get an existing study
     */
    async getStudyRaw(requestParameters: GetStudyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Study>> {
        if (requestParameters.studyId === null || requestParameters.studyId === undefined) {
            throw new runtime.RequiredError('studyId','Required parameter requestParameters.studyId was null or undefined when calling getStudy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("authorization", ["openid", "email", "profile"]);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/studies/{studyId}`.replace(`{${"studyId"}}`, encodeURIComponent(String(requestParameters.studyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StudyFromJSON(jsonValue));
    }

    /**
     * Get an existing study
     */
    async getStudy(requestParameters: GetStudyRequest, initOverrides?: RequestInit): Promise<Study> {
        const response = await this.getStudyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all studies a user can read
     */
    async listStudiesRaw(requestParameters: ListStudiesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Study>>> {
        const queryParameters: any = {};

        if (requestParameters.displayName !== undefined) {
            queryParameters['displayName'] = requestParameters.displayName;
        }

        if (requestParameters.description !== undefined) {
            queryParameters['description'] = requestParameters.description;
        }

        if (requestParameters.createdBy !== undefined) {
            queryParameters['createdBy'] = requestParameters.createdBy;
        }

        if (requestParameters.includeDeleted !== undefined) {
            queryParameters['includeDeleted'] = requestParameters.includeDeleted;
        }

        if (requestParameters.properties) {
            queryParameters['properties'] = requestParameters.properties;
        }

        if (requestParameters.userAccessGroup !== undefined) {
            queryParameters['userAccessGroup'] = requestParameters.userAccessGroup;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("authorization", ["openid", "email", "profile"]);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/studies`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(StudyFromJSON));
    }

    /**
     * List all studies a user can read
     */
    async listStudies(requestParameters: ListStudiesRequest, initOverrides?: RequestInit): Promise<Array<Study>> {
        const response = await this.listStudiesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update an existing study
     */
    async updateStudyRaw(requestParameters: UpdateStudyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Study>> {
        if (requestParameters.studyId === null || requestParameters.studyId === undefined) {
            throw new runtime.RequiredError('studyId','Required parameter requestParameters.studyId was null or undefined when calling updateStudy.');
        }

        if (requestParameters.studyUpdateInfo === null || requestParameters.studyUpdateInfo === undefined) {
            throw new runtime.RequiredError('studyUpdateInfo','Required parameter requestParameters.studyUpdateInfo was null or undefined when calling updateStudy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("authorization", ["openid", "email", "profile"]);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/studies/{studyId}`.replace(`{${"studyId"}}`, encodeURIComponent(String(requestParameters.studyId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: StudyUpdateInfoToJSON(requestParameters.studyUpdateInfo),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StudyFromJSON(jsonValue));
    }

    /**
     * Update an existing study
     */
    async updateStudy(requestParameters: UpdateStudyRequest, initOverrides?: RequestInit): Promise<Study> {
        const response = await this.updateStudyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the properties in a study. Only properties with keys in request  are updated. Properties with keys not in request are not updated. 
     */
    async updateStudyPropertiesRaw(requestParameters: UpdateStudyPropertiesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.studyId === null || requestParameters.studyId === undefined) {
            throw new runtime.RequiredError('studyId','Required parameter requestParameters.studyId was null or undefined when calling updateStudyProperties.');
        }

        if (requestParameters.propertyKeyValue === null || requestParameters.propertyKeyValue === undefined) {
            throw new runtime.RequiredError('propertyKeyValue','Required parameter requestParameters.propertyKeyValue was null or undefined when calling updateStudyProperties.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("authorization", ["openid", "email", "profile"]);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/studies/{studyId}/properties`.replace(`{${"studyId"}}`, encodeURIComponent(String(requestParameters.studyId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.propertyKeyValue.map(PropertyKeyValueToJSON),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update the properties in a study. Only properties with keys in request  are updated. Properties with keys not in request are not updated. 
     */
    async updateStudyProperties(requestParameters: UpdateStudyPropertiesRequest, initOverrides?: RequestInit): Promise<void> {
        await this.updateStudyPropertiesRaw(requestParameters, initOverrides);
    }

}
