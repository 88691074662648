/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DataType,
    DataTypeFromJSON,
    DataTypeFromJSONTyped,
    DataTypeToJSON,
} from './';

/**
 * Entity attribute
 * @export
 * @interface Attribute
 */
export interface Attribute {
    /**
     * Name
     * @type {string}
     * @memberof Attribute
     */
    name?: string;
    /**
     * Attribute type
     * @type {string}
     * @memberof Attribute
     */
    type?: AttributeTypeEnum;
    /**
     * 
     * @type {DataType}
     * @memberof Attribute
     */
    dataType?: DataType;
}

/**
* @export
* @enum {string}
*/
export enum AttributeTypeEnum {
    Simple = 'SIMPLE',
    KeyAndDisplay = 'KEY_AND_DISPLAY'
}

export function AttributeFromJSON(json: any): Attribute {
    return AttributeFromJSONTyped(json, false);
}

export function AttributeFromJSONTyped(json: any, ignoreDiscriminator: boolean): Attribute {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'dataType': !exists(json, 'dataType') ? undefined : DataTypeFromJSON(json['dataType']),
    };
}

export function AttributeToJSON(value?: Attribute | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'type': value.type,
        'dataType': DataTypeToJSON(value.dataType),
    };
}


