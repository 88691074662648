/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DisplayHint,
    DisplayHintFromJSON,
    DisplayHintFromJSONTyped,
    DisplayHintToJSON,
} from './';

/**
 * List of display hints
 * @export
 * @interface DisplayHintList
 */
export interface DisplayHintList {
    /**
     * 
     * @type {string}
     * @memberof DisplayHintList
     */
    sql?: string;
    /**
     * 
     * @type {Array<DisplayHint>}
     * @memberof DisplayHintList
     */
    displayHints?: Array<DisplayHint>;
}

export function DisplayHintListFromJSON(json: any): DisplayHintList {
    return DisplayHintListFromJSONTyped(json, false);
}

export function DisplayHintListFromJSONTyped(json: any, ignoreDiscriminator: boolean): DisplayHintList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sql': !exists(json, 'sql') ? undefined : json['sql'],
        'displayHints': !exists(json, 'displayHints') ? undefined : ((json['displayHints'] as Array<any>).map(DisplayHintFromJSON)),
    };
}

export function DisplayHintListToJSON(value?: DisplayHintList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sql': value.sql,
        'displayHints': value.displayHints === undefined ? undefined : ((value.displayHints as Array<any>).map(DisplayHintToJSON)),
    };
}


