/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Count review instances
 * @export
 * @interface ReviewCountQuery
 */
export interface ReviewCountQuery {
    /**
     * Attributes to group by. One count will be returned for each possible combination of attribute values 
     * (e.g. [gender, hair_color] will return counts for man+red, man+black, woman+red, woman+black).
     * @type {Array<string>}
     * @memberof ReviewCountQuery
     */
    attributes?: Array<string>;
}

export function ReviewCountQueryFromJSON(json: any): ReviewCountQuery {
    return ReviewCountQueryFromJSONTyped(json, false);
}

export function ReviewCountQueryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReviewCountQuery {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attributes': !exists(json, 'attributes') ? undefined : json['attributes'],
    };
}

export function ReviewCountQueryToJSON(value?: ReviewCountQuery | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attributes': value.attributes,
    };
}


