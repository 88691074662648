/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExportLinkResult
 */
export interface ExportLinkResult {
    /**
     * 
     * @type {string}
     * @memberof ExportLinkResult
     */
    displayName?: string;
    /**
     * 
     * @type {string}
     * @memberof ExportLinkResult
     */
    url?: string;
    /**
     * Ordered list of tags that indicate how this link result should be displayed to the user.
     * The first tag is the top-level section, the second tag is a sub-section, etc.
     * e.g. ["Annotations", "Cohort 123"] means this link result will be displayed in the Annotations
     * section, Cohort 123 sub-section.
     * @type {Array<string>}
     * @memberof ExportLinkResult
     */
    tags: Array<string>;
    /**
     * Optional message (e.g. "No entity data. File not generated.").
     * @type {string}
     * @memberof ExportLinkResult
     */
    message?: string;
    /**
     * Error message if generating this link result failed.
     * @type {string}
     * @memberof ExportLinkResult
     */
    error?: string;
}

export function ExportLinkResultFromJSON(json: any): ExportLinkResult {
    return ExportLinkResultFromJSONTyped(json, false);
}

export function ExportLinkResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExportLinkResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'displayName': !exists(json, 'displayName') ? undefined : json['displayName'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'tags': json['tags'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'error': !exists(json, 'error') ? undefined : json['error'],
    };
}

export function ExportLinkResultToJSON(value?: ExportLinkResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'displayName': value.displayName,
        'url': value.url,
        'tags': value.tags,
        'message': value.message,
        'error': value.error,
    };
}


