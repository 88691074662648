/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Filter,
    FilterFromJSON,
    FilterFromJSONTyped,
    FilterToJSON,
} from './';

/**
 * Compose multiple filters (e.g. color=red AND capacity=3, NOT color=red). 
 * The NOT operator only allows a single subfilter. The OR and AND operators require more than one subfilter.
 * @export
 * @interface BooleanLogicFilter
 */
export interface BooleanLogicFilter {
    /**
     * 
     * @type {string}
     * @memberof BooleanLogicFilter
     */
    operator?: BooleanLogicFilterOperatorEnum;
    /**
     * 
     * @type {Array<Filter>}
     * @memberof BooleanLogicFilter
     */
    subfilters?: Array<Filter>;
}

/**
* @export
* @enum {string}
*/
export enum BooleanLogicFilterOperatorEnum {
    And = 'AND',
    Or = 'OR',
    Not = 'NOT'
}

export function BooleanLogicFilterFromJSON(json: any): BooleanLogicFilter {
    return BooleanLogicFilterFromJSONTyped(json, false);
}

export function BooleanLogicFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): BooleanLogicFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'operator': !exists(json, 'operator') ? undefined : json['operator'],
        'subfilters': !exists(json, 'subfilters') ? undefined : ((json['subfilters'] as Array<any>).map(FilterFromJSON)),
    };
}

export function BooleanLogicFilterToJSON(value?: BooleanLogicFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'operator': value.operator,
        'subfilters': value.subfilters === undefined ? undefined : ((value.subfilters as Array<any>).map(FilterToJSON)),
    };
}


