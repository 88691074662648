/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PropertyKeyValue
 */
export interface PropertyKeyValue {
    /**
     * String to identify the key. Keys must be 1-1024 characters, using letters,
     * numbers, dashes, and underscores and must not start with a dash or underscore.
     * @type {string}
     * @memberof PropertyKeyValue
     */
    key: string;
    /**
     * Arbitrary string value
     * @type {string}
     * @memberof PropertyKeyValue
     */
    value: string;
}

export function PropertyKeyValueFromJSON(json: any): PropertyKeyValue {
    return PropertyKeyValueFromJSONTyped(json, false);
}

export function PropertyKeyValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): PropertyKeyValue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'value': json['value'],
    };
}

export function PropertyKeyValueToJSON(value?: PropertyKeyValue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'value': value.value,
    };
}


