/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface QueryRelationshipField
 */
export interface QueryRelationshipField {
    /**
     * 
     * @type {string}
     * @memberof QueryRelationshipField
     */
    relatedEntity?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryRelationshipField
     */
    hierarchy?: string;
}

export function QueryRelationshipFieldFromJSON(json: any): QueryRelationshipField {
    return QueryRelationshipFieldFromJSONTyped(json, false);
}

export function QueryRelationshipFieldFromJSONTyped(json: any, ignoreDiscriminator: boolean): QueryRelationshipField {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'relatedEntity': !exists(json, 'relatedEntity') ? undefined : json['relatedEntity'],
        'hierarchy': !exists(json, 'hierarchy') ? undefined : json['hierarchy'],
    };
}

export function QueryRelationshipFieldToJSON(value?: QueryRelationshipField | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'relatedEntity': value.relatedEntity,
        'hierarchy': value.hierarchy,
    };
}


