/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EntityOutputPreviewCriteria,
    EntityOutputPreviewCriteriaFromJSON,
    EntityOutputPreviewCriteriaFromJSONTyped,
    EntityOutputPreviewCriteriaToJSON,
} from './';

/**
 * 
 * @export
 * @interface EntityOutputPreview
 */
export interface EntityOutputPreview {
    /**
     * Entity name
     * @type {string}
     * @memberof EntityOutputPreview
     */
    entity: string;
    /**
     * Names of attributes included in the output
     * @type {Array<string>}
     * @memberof EntityOutputPreview
     */
    includedAttributes: Array<string>;
    /**
     * 
     * @type {Array<EntityOutputPreviewCriteria>}
     * @memberof EntityOutputPreview
     */
    criteria: Array<EntityOutputPreviewCriteria>;
    /**
     * SQL string against the index tables.
     * This will be populated if source queries are not configured for this entity.
     * @type {string}
     * @memberof EntityOutputPreview
     */
    indexSql?: string;
    /**
     * SQL string against the source tables.
     * This will be populated if source queries are configured for this entity.
     * @type {string}
     * @memberof EntityOutputPreview
     */
    sourceSql?: string;
}

export function EntityOutputPreviewFromJSON(json: any): EntityOutputPreview {
    return EntityOutputPreviewFromJSONTyped(json, false);
}

export function EntityOutputPreviewFromJSONTyped(json: any, ignoreDiscriminator: boolean): EntityOutputPreview {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entity': json['entity'],
        'includedAttributes': json['includedAttributes'],
        'criteria': ((json['criteria'] as Array<any>).map(EntityOutputPreviewCriteriaFromJSON)),
        'indexSql': !exists(json, 'indexSql') ? undefined : json['indexSql'],
        'sourceSql': !exists(json, 'sourceSql') ? undefined : json['sourceSql'],
    };
}

export function EntityOutputPreviewToJSON(value?: EntityOutputPreview | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entity': value.entity,
        'includedAttributes': value.includedAttributes,
        'criteria': ((value.criteria as Array<any>).map(EntityOutputPreviewCriteriaToJSON)),
        'indexSql': value.indexSql,
        'sourceSql': value.sourceSql,
    };
}


