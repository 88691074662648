/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Filter on a text search (e.g. person sounds like Joe)
 * @export
 * @interface TextFilter
 */
export interface TextFilter {
    /**
     * 
     * @type {string}
     * @memberof TextFilter
     */
    matchType?: TextFilterMatchTypeEnum;
    /**
     * Attribute to match on. If not specified, then we match on whatever is specified in the text search mapping config.
     * Currently, fuzzy match only works on a single attribute (i.e. you must specify this field for fuzzy match).
     * @type {string}
     * @memberof TextFilter
     */
    attribute?: string;
    /**
     * 
     * @type {string}
     * @memberof TextFilter
     */
    text?: string;
}

/**
* @export
* @enum {string}
*/
export enum TextFilterMatchTypeEnum {
    ExactMatch = 'EXACT_MATCH',
    FuzzyMatch = 'FUZZY_MATCH'
}

export function TextFilterFromJSON(json: any): TextFilter {
    return TextFilterFromJSONTyped(json, false);
}

export function TextFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): TextFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'matchType': !exists(json, 'matchType') ? undefined : json['matchType'],
        'attribute': !exists(json, 'attribute') ? undefined : json['attribute'],
        'text': !exists(json, 'text') ? undefined : json['text'],
    };
}

export function TextFilterToJSON(value?: TextFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'matchType': value.matchType,
        'attribute': value.attribute,
        'text': value.text,
    };
}


