/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Defaults to ascending
 * @export
 * @enum {string}
 */
export enum OrderByDirection {
    Ascending = 'ASCENDING',
    Descending = 'DESCENDING'
}

export function OrderByDirectionFromJSON(json: any): OrderByDirection {
    return OrderByDirectionFromJSONTyped(json, false);
}

export function OrderByDirectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderByDirection {
    return json as OrderByDirection;
}

export function OrderByDirectionToJSON(value?: OrderByDirection | null): any {
    return value as any;
}

