/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Value } from "../value";
import { DataRange } from "./data_range";

export const protobufPackage = "tanagra";

/** Configuration for a value that has been selected. */
export interface ValueData {
  /** The attribute that was selected. */
  attribute: string;
  /**
   * Whether this is a numeric or categorical value. Stored to avoid extra
   * lookups when rendering.
   */
  numeric: boolean;
  selected: ValueData_Selection[];
  /** The selected numeric range when referencing a numeric value (e.g. age). */
  range: DataRange | undefined;
}

/** A single selected categorical value (e.g. {value: 1234, name: "Diabetes"}. */
export interface ValueData_Selection {
  /** The value used to build queries. */
  value:
    | Value
    | undefined;
  /**
   * The visible name for the selection. This is stored to avoid extra lookups
   * when rendering.
   */
  name: string;
}

function createBaseValueData(): ValueData {
  return { attribute: "", numeric: false, selected: [], range: undefined };
}

export const ValueData = {
  encode(message: ValueData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.attribute !== "") {
      writer.uint32(10).string(message.attribute);
    }
    if (message.numeric === true) {
      writer.uint32(16).bool(message.numeric);
    }
    for (const v of message.selected) {
      ValueData_Selection.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.range !== undefined) {
      DataRange.encode(message.range, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ValueData {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseValueData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.attribute = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.numeric = reader.bool();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.selected.push(ValueData_Selection.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.range = DataRange.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ValueData {
    return {
      attribute: isSet(object.attribute) ? globalThis.String(object.attribute) : "",
      numeric: isSet(object.numeric) ? globalThis.Boolean(object.numeric) : false,
      selected: globalThis.Array.isArray(object?.selected)
        ? object.selected.map((e: any) => ValueData_Selection.fromJSON(e))
        : [],
      range: isSet(object.range) ? DataRange.fromJSON(object.range) : undefined,
    };
  },

  toJSON(message: ValueData): unknown {
    const obj: any = {};
    if (message.attribute !== "") {
      obj.attribute = message.attribute;
    }
    if (message.numeric === true) {
      obj.numeric = message.numeric;
    }
    if (message.selected?.length) {
      obj.selected = message.selected.map((e) => ValueData_Selection.toJSON(e));
    }
    if (message.range !== undefined) {
      obj.range = DataRange.toJSON(message.range);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ValueData>, I>>(base?: I): ValueData {
    return ValueData.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ValueData>, I>>(object: I): ValueData {
    const message = createBaseValueData();
    message.attribute = object.attribute ?? "";
    message.numeric = object.numeric ?? false;
    message.selected = object.selected?.map((e) => ValueData_Selection.fromPartial(e)) || [];
    message.range = (object.range !== undefined && object.range !== null)
      ? DataRange.fromPartial(object.range)
      : undefined;
    return message;
  },
};

function createBaseValueData_Selection(): ValueData_Selection {
  return { value: undefined, name: "" };
}

export const ValueData_Selection = {
  encode(message: ValueData_Selection, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.value !== undefined) {
      Value.encode(message.value, writer.uint32(10).fork()).ldelim();
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ValueData_Selection {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseValueData_Selection();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.value = Value.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ValueData_Selection {
    return {
      value: isSet(object.value) ? Value.fromJSON(object.value) : undefined,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
    };
  },

  toJSON(message: ValueData_Selection): unknown {
    const obj: any = {};
    if (message.value !== undefined) {
      obj.value = Value.toJSON(message.value);
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ValueData_Selection>, I>>(base?: I): ValueData_Selection {
    return ValueData_Selection.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ValueData_Selection>, I>>(object: I): ValueData_Selection {
    const message = createBaseValueData_Selection();
    message.value = (object.value !== undefined && object.value !== null) ? Value.fromPartial(object.value) : undefined;
    message.name = object.name ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
