/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Column } from "../../column";
import { SortOrder } from "../../sort_order";
import { ValueConfig } from "../value_config";

export const protobufPackage = "tanagra.configschema";

/**
 * Config for an GroupItems entity group criteria with filterable attributes and
 * the ability to select all items that match a set of attributes.
 */
export interface FilterableGroup {
  /** Columns displayed in the list view. */
  columns: Column[];
  /** The GroupItems entity group to be filtered. */
  entityGroup: string;
  /** Attributes that can be part of a select all. */
  valueConfigs: ValueConfig[];
  /** The sort order to use in the list view. */
  sortOrder:
    | SortOrder
    | undefined;
  /**
   * Number of values to display on each page in the list view. Otherwise, a
   * default value is applied.
   */
  pageSize?: number | undefined;
}

function createBaseFilterableGroup(): FilterableGroup {
  return { columns: [], entityGroup: "", valueConfigs: [], sortOrder: undefined, pageSize: undefined };
}

export const FilterableGroup = {
  encode(message: FilterableGroup, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.columns) {
      Column.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.entityGroup !== "") {
      writer.uint32(18).string(message.entityGroup);
    }
    for (const v of message.valueConfigs) {
      ValueConfig.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.sortOrder !== undefined) {
      SortOrder.encode(message.sortOrder, writer.uint32(34).fork()).ldelim();
    }
    if (message.pageSize !== undefined) {
      writer.uint32(40).int32(message.pageSize);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FilterableGroup {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFilterableGroup();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.columns.push(Column.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.entityGroup = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.valueConfigs.push(ValueConfig.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.sortOrder = SortOrder.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.pageSize = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FilterableGroup {
    return {
      columns: globalThis.Array.isArray(object?.columns) ? object.columns.map((e: any) => Column.fromJSON(e)) : [],
      entityGroup: isSet(object.entityGroup) ? globalThis.String(object.entityGroup) : "",
      valueConfigs: globalThis.Array.isArray(object?.valueConfigs)
        ? object.valueConfigs.map((e: any) => ValueConfig.fromJSON(e))
        : [],
      sortOrder: isSet(object.sortOrder) ? SortOrder.fromJSON(object.sortOrder) : undefined,
      pageSize: isSet(object.pageSize) ? globalThis.Number(object.pageSize) : undefined,
    };
  },

  toJSON(message: FilterableGroup): unknown {
    const obj: any = {};
    if (message.columns?.length) {
      obj.columns = message.columns.map((e) => Column.toJSON(e));
    }
    if (message.entityGroup !== "") {
      obj.entityGroup = message.entityGroup;
    }
    if (message.valueConfigs?.length) {
      obj.valueConfigs = message.valueConfigs.map((e) => ValueConfig.toJSON(e));
    }
    if (message.sortOrder !== undefined) {
      obj.sortOrder = SortOrder.toJSON(message.sortOrder);
    }
    if (message.pageSize !== undefined) {
      obj.pageSize = Math.round(message.pageSize);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FilterableGroup>, I>>(base?: I): FilterableGroup {
    return FilterableGroup.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FilterableGroup>, I>>(object: I): FilterableGroup {
    const message = createBaseFilterableGroup();
    message.columns = object.columns?.map((e) => Column.fromPartial(e)) || [];
    message.entityGroup = object.entityGroup ?? "";
    message.valueConfigs = object.valueConfigs?.map((e) => ValueConfig.fromPartial(e)) || [];
    message.sortOrder = (object.sortOrder !== undefined && object.sortOrder !== null)
      ? SortOrder.fromPartial(object.sortOrder)
      : undefined;
    message.pageSize = object.pageSize ?? undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
