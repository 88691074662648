/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Contents of the underlay config files
 * @export
 * @interface UnderlaySerializedConfiguration
 */
export interface UnderlaySerializedConfiguration {
    /**
     * Contents of the underlay.json file
     * @type {string}
     * @memberof UnderlaySerializedConfiguration
     */
    underlay: string;
    /**
     * Contents of the entity.json files
     * @type {Array<string>}
     * @memberof UnderlaySerializedConfiguration
     */
    entities: Array<string>;
    /**
     * Contents of the group-items type entityGroup.json files
     * @type {Array<string>}
     * @memberof UnderlaySerializedConfiguration
     */
    groupItemsEntityGroups: Array<string>;
    /**
     * Contents of the criteria-occurrence type entityGroup.json files
     * @type {Array<string>}
     * @memberof UnderlaySerializedConfiguration
     */
    criteriaOccurrenceEntityGroups: Array<string>;
    /**
     * Contents of the criteria selector json files
     * @type {Array<string>}
     * @memberof UnderlaySerializedConfiguration
     */
    criteriaSelectors?: Array<string>;
    /**
     * Contents of the prepackaged data feature json files
     * @type {Array<string>}
     * @memberof UnderlaySerializedConfiguration
     */
    prepackagedDataFeatures?: Array<string>;
    /**
     * Contents of the visualization json files
     * @type {Array<string>}
     * @memberof UnderlaySerializedConfiguration
     */
    visualizations?: Array<string>;
}

export function UnderlaySerializedConfigurationFromJSON(json: any): UnderlaySerializedConfiguration {
    return UnderlaySerializedConfigurationFromJSONTyped(json, false);
}

export function UnderlaySerializedConfigurationFromJSONTyped(json: any, ignoreDiscriminator: boolean): UnderlaySerializedConfiguration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'underlay': json['underlay'],
        'entities': json['entities'],
        'groupItemsEntityGroups': json['groupItemsEntityGroups'],
        'criteriaOccurrenceEntityGroups': json['criteriaOccurrenceEntityGroups'],
        'criteriaSelectors': !exists(json, 'criteriaSelectors') ? undefined : json['criteriaSelectors'],
        'prepackagedDataFeatures': !exists(json, 'prepackagedDataFeatures') ? undefined : json['prepackagedDataFeatures'],
        'visualizations': !exists(json, 'visualizations') ? undefined : json['visualizations'],
    };
}

export function UnderlaySerializedConfigurationToJSON(value?: UnderlaySerializedConfiguration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'underlay': value.underlay,
        'entities': value.entities,
        'groupItemsEntityGroups': value.groupItemsEntityGroups,
        'criteriaOccurrenceEntityGroups': value.criteriaOccurrenceEntityGroups,
        'criteriaSelectors': value.criteriaSelectors,
        'prepackagedDataFeatures': value.prepackagedDataFeatures,
        'visualizations': value.visualizations,
    };
}


