/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ErrorReport,
    ErrorReportFromJSON,
    ErrorReportToJSON,
    InstanceCountList,
    InstanceCountListFromJSON,
    InstanceCountListToJSON,
    Review,
    ReviewFromJSON,
    ReviewToJSON,
    ReviewCountQuery,
    ReviewCountQueryFromJSON,
    ReviewCountQueryToJSON,
    ReviewCreateInfo,
    ReviewCreateInfoFromJSON,
    ReviewCreateInfoToJSON,
    ReviewInstanceListResult,
    ReviewInstanceListResultFromJSON,
    ReviewInstanceListResultToJSON,
    ReviewQuery,
    ReviewQueryFromJSON,
    ReviewQueryToJSON,
    ReviewUpdateInfo,
    ReviewUpdateInfoFromJSON,
    ReviewUpdateInfoToJSON,
} from '../models';

export interface CountReviewInstancesRequest {
    studyId: string;
    cohortId: string;
    reviewId: string;
    reviewCountQuery: ReviewCountQuery;
}

export interface CreateReviewRequest {
    studyId: string;
    cohortId: string;
    reviewCreateInfo: ReviewCreateInfo;
}

export interface DeleteReviewRequest {
    studyId: string;
    cohortId: string;
    reviewId: string;
}

export interface GetReviewRequest {
    studyId: string;
    cohortId: string;
    reviewId: string;
}

export interface ListReviewInstancesAndAnnotationsRequest {
    studyId: string;
    cohortId: string;
    reviewId: string;
    reviewQuery: ReviewQuery;
}

export interface ListReviewsRequest {
    studyId: string;
    cohortId: string;
    offset?: number;
    limit?: number;
}

export interface UpdateReviewRequest {
    studyId: string;
    cohortId: string;
    reviewId: string;
    reviewUpdateInfo: ReviewUpdateInfo;
}

/**
 * 
 */
export class ReviewsApi extends runtime.BaseAPI {

    /**
     * Count primary entity instances in a review
     */
    async countReviewInstancesRaw(requestParameters: CountReviewInstancesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InstanceCountList>> {
        if (requestParameters.studyId === null || requestParameters.studyId === undefined) {
            throw new runtime.RequiredError('studyId','Required parameter requestParameters.studyId was null or undefined when calling countReviewInstances.');
        }

        if (requestParameters.cohortId === null || requestParameters.cohortId === undefined) {
            throw new runtime.RequiredError('cohortId','Required parameter requestParameters.cohortId was null or undefined when calling countReviewInstances.');
        }

        if (requestParameters.reviewId === null || requestParameters.reviewId === undefined) {
            throw new runtime.RequiredError('reviewId','Required parameter requestParameters.reviewId was null or undefined when calling countReviewInstances.');
        }

        if (requestParameters.reviewCountQuery === null || requestParameters.reviewCountQuery === undefined) {
            throw new runtime.RequiredError('reviewCountQuery','Required parameter requestParameters.reviewCountQuery was null or undefined when calling countReviewInstances.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("authorization", ["openid", "email", "profile"]);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/studies/{studyId}/cohorts/{cohortId}/reviews/{reviewId}/counts`.replace(`{${"studyId"}}`, encodeURIComponent(String(requestParameters.studyId))).replace(`{${"cohortId"}}`, encodeURIComponent(String(requestParameters.cohortId))).replace(`{${"reviewId"}}`, encodeURIComponent(String(requestParameters.reviewId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReviewCountQueryToJSON(requestParameters.reviewCountQuery),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InstanceCountListFromJSON(jsonValue));
    }

    /**
     * Count primary entity instances in a review
     */
    async countReviewInstances(requestParameters: CountReviewInstancesRequest, initOverrides?: RequestInit): Promise<InstanceCountList> {
        const response = await this.countReviewInstancesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new review
     */
    async createReviewRaw(requestParameters: CreateReviewRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Review>> {
        if (requestParameters.studyId === null || requestParameters.studyId === undefined) {
            throw new runtime.RequiredError('studyId','Required parameter requestParameters.studyId was null or undefined when calling createReview.');
        }

        if (requestParameters.cohortId === null || requestParameters.cohortId === undefined) {
            throw new runtime.RequiredError('cohortId','Required parameter requestParameters.cohortId was null or undefined when calling createReview.');
        }

        if (requestParameters.reviewCreateInfo === null || requestParameters.reviewCreateInfo === undefined) {
            throw new runtime.RequiredError('reviewCreateInfo','Required parameter requestParameters.reviewCreateInfo was null or undefined when calling createReview.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("authorization", ["openid", "email", "profile"]);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/studies/{studyId}/cohorts/{cohortId}/reviews`.replace(`{${"studyId"}}`, encodeURIComponent(String(requestParameters.studyId))).replace(`{${"cohortId"}}`, encodeURIComponent(String(requestParameters.cohortId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReviewCreateInfoToJSON(requestParameters.reviewCreateInfo),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReviewFromJSON(jsonValue));
    }

    /**
     * Create a new review
     */
    async createReview(requestParameters: CreateReviewRequest, initOverrides?: RequestInit): Promise<Review> {
        const response = await this.createReviewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a review
     */
    async deleteReviewRaw(requestParameters: DeleteReviewRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.studyId === null || requestParameters.studyId === undefined) {
            throw new runtime.RequiredError('studyId','Required parameter requestParameters.studyId was null or undefined when calling deleteReview.');
        }

        if (requestParameters.cohortId === null || requestParameters.cohortId === undefined) {
            throw new runtime.RequiredError('cohortId','Required parameter requestParameters.cohortId was null or undefined when calling deleteReview.');
        }

        if (requestParameters.reviewId === null || requestParameters.reviewId === undefined) {
            throw new runtime.RequiredError('reviewId','Required parameter requestParameters.reviewId was null or undefined when calling deleteReview.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("authorization", ["openid", "email", "profile"]);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/studies/{studyId}/cohorts/{cohortId}/reviews/{reviewId}`.replace(`{${"studyId"}}`, encodeURIComponent(String(requestParameters.studyId))).replace(`{${"cohortId"}}`, encodeURIComponent(String(requestParameters.cohortId))).replace(`{${"reviewId"}}`, encodeURIComponent(String(requestParameters.reviewId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a review
     */
    async deleteReview(requestParameters: DeleteReviewRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteReviewRaw(requestParameters, initOverrides);
    }

    /**
     * Get an existing review
     */
    async getReviewRaw(requestParameters: GetReviewRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Review>> {
        if (requestParameters.studyId === null || requestParameters.studyId === undefined) {
            throw new runtime.RequiredError('studyId','Required parameter requestParameters.studyId was null or undefined when calling getReview.');
        }

        if (requestParameters.cohortId === null || requestParameters.cohortId === undefined) {
            throw new runtime.RequiredError('cohortId','Required parameter requestParameters.cohortId was null or undefined when calling getReview.');
        }

        if (requestParameters.reviewId === null || requestParameters.reviewId === undefined) {
            throw new runtime.RequiredError('reviewId','Required parameter requestParameters.reviewId was null or undefined when calling getReview.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("authorization", ["openid", "email", "profile"]);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/studies/{studyId}/cohorts/{cohortId}/reviews/{reviewId}`.replace(`{${"studyId"}}`, encodeURIComponent(String(requestParameters.studyId))).replace(`{${"cohortId"}}`, encodeURIComponent(String(requestParameters.cohortId))).replace(`{${"reviewId"}}`, encodeURIComponent(String(requestParameters.reviewId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReviewFromJSON(jsonValue));
    }

    /**
     * Get an existing review
     */
    async getReview(requestParameters: GetReviewRequest, initOverrides?: RequestInit): Promise<Review> {
        const response = await this.getReviewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List primary entity instances in a review and any associated annotation values
     */
    async listReviewInstancesAndAnnotationsRaw(requestParameters: ListReviewInstancesAndAnnotationsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ReviewInstanceListResult>> {
        if (requestParameters.studyId === null || requestParameters.studyId === undefined) {
            throw new runtime.RequiredError('studyId','Required parameter requestParameters.studyId was null or undefined when calling listReviewInstancesAndAnnotations.');
        }

        if (requestParameters.cohortId === null || requestParameters.cohortId === undefined) {
            throw new runtime.RequiredError('cohortId','Required parameter requestParameters.cohortId was null or undefined when calling listReviewInstancesAndAnnotations.');
        }

        if (requestParameters.reviewId === null || requestParameters.reviewId === undefined) {
            throw new runtime.RequiredError('reviewId','Required parameter requestParameters.reviewId was null or undefined when calling listReviewInstancesAndAnnotations.');
        }

        if (requestParameters.reviewQuery === null || requestParameters.reviewQuery === undefined) {
            throw new runtime.RequiredError('reviewQuery','Required parameter requestParameters.reviewQuery was null or undefined when calling listReviewInstancesAndAnnotations.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("authorization", ["openid", "email", "profile"]);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/studies/{studyId}/cohorts/{cohortId}/reviews/{reviewId}/instances`.replace(`{${"studyId"}}`, encodeURIComponent(String(requestParameters.studyId))).replace(`{${"cohortId"}}`, encodeURIComponent(String(requestParameters.cohortId))).replace(`{${"reviewId"}}`, encodeURIComponent(String(requestParameters.reviewId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReviewQueryToJSON(requestParameters.reviewQuery),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReviewInstanceListResultFromJSON(jsonValue));
    }

    /**
     * List primary entity instances in a review and any associated annotation values
     */
    async listReviewInstancesAndAnnotations(requestParameters: ListReviewInstancesAndAnnotationsRequest, initOverrides?: RequestInit): Promise<ReviewInstanceListResult> {
        const response = await this.listReviewInstancesAndAnnotationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all reviews for a cohort
     */
    async listReviewsRaw(requestParameters: ListReviewsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Review>>> {
        if (requestParameters.studyId === null || requestParameters.studyId === undefined) {
            throw new runtime.RequiredError('studyId','Required parameter requestParameters.studyId was null or undefined when calling listReviews.');
        }

        if (requestParameters.cohortId === null || requestParameters.cohortId === undefined) {
            throw new runtime.RequiredError('cohortId','Required parameter requestParameters.cohortId was null or undefined when calling listReviews.');
        }

        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("authorization", ["openid", "email", "profile"]);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/studies/{studyId}/cohorts/{cohortId}/reviews`.replace(`{${"studyId"}}`, encodeURIComponent(String(requestParameters.studyId))).replace(`{${"cohortId"}}`, encodeURIComponent(String(requestParameters.cohortId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReviewFromJSON));
    }

    /**
     * List all reviews for a cohort
     */
    async listReviews(requestParameters: ListReviewsRequest, initOverrides?: RequestInit): Promise<Array<Review>> {
        const response = await this.listReviewsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update an existing review
     */
    async updateReviewRaw(requestParameters: UpdateReviewRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Review>> {
        if (requestParameters.studyId === null || requestParameters.studyId === undefined) {
            throw new runtime.RequiredError('studyId','Required parameter requestParameters.studyId was null or undefined when calling updateReview.');
        }

        if (requestParameters.cohortId === null || requestParameters.cohortId === undefined) {
            throw new runtime.RequiredError('cohortId','Required parameter requestParameters.cohortId was null or undefined when calling updateReview.');
        }

        if (requestParameters.reviewId === null || requestParameters.reviewId === undefined) {
            throw new runtime.RequiredError('reviewId','Required parameter requestParameters.reviewId was null or undefined when calling updateReview.');
        }

        if (requestParameters.reviewUpdateInfo === null || requestParameters.reviewUpdateInfo === undefined) {
            throw new runtime.RequiredError('reviewUpdateInfo','Required parameter requestParameters.reviewUpdateInfo was null or undefined when calling updateReview.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("authorization", ["openid", "email", "profile"]);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/studies/{studyId}/cohorts/{cohortId}/reviews/{reviewId}`.replace(`{${"studyId"}}`, encodeURIComponent(String(requestParameters.studyId))).replace(`{${"cohortId"}}`, encodeURIComponent(String(requestParameters.cohortId))).replace(`{${"reviewId"}}`, encodeURIComponent(String(requestParameters.reviewId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ReviewUpdateInfoToJSON(requestParameters.reviewUpdateInfo),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReviewFromJSON(jsonValue));
    }

    /**
     * Update an existing review
     */
    async updateReview(requestParameters: UpdateReviewRequest, initOverrides?: RequestInit): Promise<Review> {
        const response = await this.updateReviewRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
