/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InstanceHierarchyFields,
    InstanceHierarchyFieldsFromJSON,
    InstanceHierarchyFieldsFromJSONTyped,
    InstanceHierarchyFieldsToJSON,
    InstanceRelationshipFields,
    InstanceRelationshipFieldsFromJSON,
    InstanceRelationshipFieldsFromJSONTyped,
    InstanceRelationshipFieldsToJSON,
    ValueDisplay,
    ValueDisplayFromJSON,
    ValueDisplayFromJSONTyped,
    ValueDisplayToJSON,
} from './';

/**
 * 
 * @export
 * @interface Instance
 */
export interface Instance {
    /**
     * A map of entity attribute names to their value for this instance. Only the attributes requested are populated.
     * @type {{ [key: string]: ValueDisplay; }}
     * @memberof Instance
     */
    attributes?: { [key: string]: ValueDisplay; };
    /**
     * Hierarchy field values for this instance. One set of values per hierarchy. Only the values requested are populated.
     * @type {Array<InstanceHierarchyFields>}
     * @memberof Instance
     */
    hierarchyFields?: Array<InstanceHierarchyFields>;
    /**
     * Relationship field values for this instance. Only the values requested are populated.
     * @type {Array<InstanceRelationshipFields>}
     * @memberof Instance
     */
    relationshipFields?: Array<InstanceRelationshipFields>;
}

export function InstanceFromJSON(json: any): Instance {
    return InstanceFromJSONTyped(json, false);
}

export function InstanceFromJSONTyped(json: any, ignoreDiscriminator: boolean): Instance {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attributes': !exists(json, 'attributes') ? undefined : (mapValues(json['attributes'], ValueDisplayFromJSON)),
        'hierarchyFields': !exists(json, 'hierarchyFields') ? undefined : ((json['hierarchyFields'] as Array<any>).map(InstanceHierarchyFieldsFromJSON)),
        'relationshipFields': !exists(json, 'relationshipFields') ? undefined : ((json['relationshipFields'] as Array<any>).map(InstanceRelationshipFieldsFromJSON)),
    };
}

export function InstanceToJSON(value?: Instance | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attributes': value.attributes === undefined ? undefined : (mapValues(value.attributes, ValueDisplayToJSON)),
        'hierarchyFields': value.hierarchyFields === undefined ? undefined : ((value.hierarchyFields as Array<any>).map(InstanceHierarchyFieldsToJSON)),
        'relationshipFields': value.relationshipFields === undefined ? undefined : ((value.relationshipFields as Array<any>).map(InstanceRelationshipFieldsToJSON)),
    };
}


