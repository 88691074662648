/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CriteriaGroupSection,
    CriteriaGroupSectionFromJSON,
    CriteriaGroupSectionFromJSONTyped,
    CriteriaGroupSectionToJSON,
} from './';

/**
 * 
 * @export
 * @interface Cohort
 */
export interface Cohort {
    /**
     * ID of the cohort, immutable
     * @type {string}
     * @memberof Cohort
     */
    id: string;
    /**
     * ID of a frozen cohort revision, immutable
     * @type {string}
     * @memberof Cohort
     */
    revisionId?: string;
    /**
     * Name of the underlay, immutable
     * @type {string}
     * @memberof Cohort
     */
    underlayName: string;
    /**
     * Human readable name of the cohort
     * @type {string}
     * @memberof Cohort
     */
    displayName: string;
    /**
     * Description of the cohort
     * @type {string}
     * @memberof Cohort
     */
    description?: string;
    /**
     * Criteria group sections that define the entity filter
     * @type {Array<CriteriaGroupSection>}
     * @memberof Cohort
     */
    criteriaGroupSections?: Array<CriteriaGroupSection>;
    /**
     * Timestamp of when the cohort was created
     * @type {Date}
     * @memberof Cohort
     */
    created: Date;
    /**
     * Email of user who created cohort
     * @type {string}
     * @memberof Cohort
     */
    createdBy: string;
    /**
     * Timestamp of when the cohort was last modified
     * @type {Date}
     * @memberof Cohort
     */
    lastModified: Date;
    /**
     * 
     * @type {boolean}
     * @memberof Cohort
     */
    isDeleted?: boolean;
}

export function CohortFromJSON(json: any): Cohort {
    return CohortFromJSONTyped(json, false);
}

export function CohortFromJSONTyped(json: any, ignoreDiscriminator: boolean): Cohort {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'revisionId': !exists(json, 'revisionId') ? undefined : json['revisionId'],
        'underlayName': json['underlayName'],
        'displayName': json['displayName'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'criteriaGroupSections': !exists(json, 'criteriaGroupSections') ? undefined : ((json['criteriaGroupSections'] as Array<any>).map(CriteriaGroupSectionFromJSON)),
        'created': (new Date(json['created'])),
        'createdBy': json['createdBy'],
        'lastModified': (new Date(json['lastModified'])),
        'isDeleted': !exists(json, 'isDeleted') ? undefined : json['isDeleted'],
    };
}

export function CohortToJSON(value?: Cohort | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'revisionId': value.revisionId,
        'underlayName': value.underlayName,
        'displayName': value.displayName,
        'description': value.description,
        'criteriaGroupSections': value.criteriaGroupSections === undefined ? undefined : ((value.criteriaGroupSections as Array<any>).map(CriteriaGroupSectionToJSON)),
        'created': (value.created.toISOString()),
        'createdBy': value.createdBy,
        'lastModified': (value.lastModified.toISOString()),
        'isDeleted': value.isDeleted,
    };
}


