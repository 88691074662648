/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LiteralValueUnion
 */
export interface LiteralValueUnion {
    /**
     * 
     * @type {boolean}
     * @memberof LiteralValueUnion
     */
    boolVal?: boolean;
    /**
     * 
     * @type {number}
     * @memberof LiteralValueUnion
     */
    int64Val?: number;
    /**
     * 
     * @type {string}
     * @memberof LiteralValueUnion
     */
    stringVal?: string;
    /**
     * Format YYYY-MM-DD
     * @type {string}
     * @memberof LiteralValueUnion
     */
    dateVal?: string;
    /**
     * Format YYYY-MM-DD hh:mm:ss
     * @type {string}
     * @memberof LiteralValueUnion
     */
    timestampVal?: string;
    /**
     * 
     * @type {number}
     * @memberof LiteralValueUnion
     */
    doubleVal?: number;
}

export function LiteralValueUnionFromJSON(json: any): LiteralValueUnion {
    return LiteralValueUnionFromJSONTyped(json, false);
}

export function LiteralValueUnionFromJSONTyped(json: any, ignoreDiscriminator: boolean): LiteralValueUnion {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'boolVal': !exists(json, 'boolVal') ? undefined : json['boolVal'],
        'int64Val': !exists(json, 'int64Val') ? undefined : json['int64Val'],
        'stringVal': !exists(json, 'stringVal') ? undefined : json['stringVal'],
        'dateVal': !exists(json, 'dateVal') ? undefined : json['dateVal'],
        'timestampVal': !exists(json, 'timestampVal') ? undefined : json['timestampVal'],
        'doubleVal': !exists(json, 'doubleVal') ? undefined : json['doubleVal'],
    };
}

export function LiteralValueUnionToJSON(value?: LiteralValueUnion | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'boolVal': value.boolVal,
        'int64Val': value.int64Val,
        'stringVal': value.stringVal,
        'dateVal': value.dateVal,
        'timestampVal': value.timestampVal,
        'doubleVal': value.doubleVal,
    };
}


