/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Criteria,
    CriteriaFromJSON,
    CriteriaFromJSONTyped,
    CriteriaToJSON,
} from './';

/**
 * Group of related criteria (primary + modifiers)
 * @export
 * @interface CriteriaGroup
 */
export interface CriteriaGroup {
    /**
     * ID of the group, immutable
     * @type {string}
     * @memberof CriteriaGroup
     */
    id: string;
    /**
     * Name of the group
     * @type {string}
     * @memberof CriteriaGroup
     */
    displayName: string;
    /**
     * Set of criteria in the group
     * @type {Array<Criteria>}
     * @memberof CriteriaGroup
     */
    criteria: Array<Criteria>;
    /**
     * True to disable the group
     * @type {boolean}
     * @memberof CriteriaGroup
     */
    disabled?: boolean;
}

export function CriteriaGroupFromJSON(json: any): CriteriaGroup {
    return CriteriaGroupFromJSONTyped(json, false);
}

export function CriteriaGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): CriteriaGroup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'displayName': json['displayName'],
        'criteria': ((json['criteria'] as Array<any>).map(CriteriaFromJSON)),
        'disabled': !exists(json, 'disabled') ? undefined : json['disabled'],
    };
}

export function CriteriaGroupToJSON(value?: CriteriaGroup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'displayName': value.displayName,
        'criteria': ((value.criteria as Array<any>).map(CriteriaToJSON)),
        'disabled': value.disabled,
    };
}


