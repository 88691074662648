/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Attribute,
    AttributeFromJSON,
    AttributeFromJSONTyped,
    AttributeToJSON,
} from './';

/**
 * Entity
 * @export
 * @interface Entity
 */
export interface Entity {
    /**
     * Name
     * @type {string}
     * @memberof Entity
     */
    name: string;
    /**
     * ID attribute name
     * @type {string}
     * @memberof Entity
     */
    idAttribute: string;
    /**
     * List of attributes
     * @type {Array<Attribute>}
     * @memberof Entity
     */
    attributes?: Array<Attribute>;
}

export function EntityFromJSON(json: any): Entity {
    return EntityFromJSONTyped(json, false);
}

export function EntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): Entity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'idAttribute': json['idAttribute'],
        'attributes': !exists(json, 'attributes') ? undefined : ((json['attributes'] as Array<any>).map(AttributeFromJSON)),
    };
}

export function EntityToJSON(value?: Entity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'idAttribute': value.idAttribute,
        'attributes': value.attributes === undefined ? undefined : ((value.attributes as Array<any>).map(AttributeToJSON)),
    };
}


