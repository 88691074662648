/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExportModel
 */
export interface ExportModel {
    /**
     * 
     * @type {string}
     * @memberof ExportModel
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ExportModel
     */
    displayName?: string;
    /**
     * 
     * @type {string}
     * @memberof ExportModel
     */
    description?: string | null;
    /**
     * Maximum number of primary entity instances, across all selected cohorts, that this model can export.
     * @type {number}
     * @memberof ExportModel
     */
    numPrimaryEntityCap?: number;
    /**
     * Map of input parameter names to descriptions
     * @type {{ [key: string]: string; }}
     * @memberof ExportModel
     */
    inputs?: { [key: string]: string; };
    /**
     * Map of output parameters names to descriptions
     * @type {{ [key: string]: string; }}
     * @memberof ExportModel
     */
    outputs?: { [key: string]: string; };
}

export function ExportModelFromJSON(json: any): ExportModel {
    return ExportModelFromJSONTyped(json, false);
}

export function ExportModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExportModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'displayName': !exists(json, 'displayName') ? undefined : json['displayName'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'numPrimaryEntityCap': !exists(json, 'numPrimaryEntityCap') ? undefined : json['numPrimaryEntityCap'],
        'inputs': !exists(json, 'inputs') ? undefined : json['inputs'],
        'outputs': !exists(json, 'outputs') ? undefined : json['outputs'],
    };
}

export function ExportModelToJSON(value?: ExportModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'displayName': value.displayName,
        'description': value.description,
        'numPrimaryEntityCap': value.numPrimaryEntityCap,
        'inputs': value.inputs,
        'outputs': value.outputs,
    };
}


