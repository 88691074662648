/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Hierarchy fields to include in the returned instances. All fields will be returned for each hierarchy specified.
 * @export
 * @interface QueryIncludeHierarchyFields
 */
export interface QueryIncludeHierarchyFields {
    /**
     * Hierarchy names
     * @type {Array<string>}
     * @memberof QueryIncludeHierarchyFields
     */
    hierarchies?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof QueryIncludeHierarchyFields
     */
    fields?: Array<QueryIncludeHierarchyFieldsFieldsEnum>;
}

/**
* @export
* @enum {string}
*/
export enum QueryIncludeHierarchyFieldsFieldsEnum {
    IsMember = 'IS_MEMBER',
    Path = 'PATH',
    NumChildren = 'NUM_CHILDREN',
    IsRoot = 'IS_ROOT'
}

export function QueryIncludeHierarchyFieldsFromJSON(json: any): QueryIncludeHierarchyFields {
    return QueryIncludeHierarchyFieldsFromJSONTyped(json, false);
}

export function QueryIncludeHierarchyFieldsFromJSONTyped(json: any, ignoreDiscriminator: boolean): QueryIncludeHierarchyFields {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'hierarchies': !exists(json, 'hierarchies') ? undefined : json['hierarchies'],
        'fields': !exists(json, 'fields') ? undefined : json['fields'],
    };
}

export function QueryIncludeHierarchyFieldsToJSON(value?: QueryIncludeHierarchyFields | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'hierarchies': value.hierarchies,
        'fields': value.fields,
    };
}


